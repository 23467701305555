@import '../breakpoints.scss';
@import '../variables.scss';

.gallery {
    .title {
        font-size: 30px;
        color: $ui-09;
        width: fit-content;
        text-transform: uppercase;
        padding-bottom: 10px;
        border-bottom: 1px solid $ui-06;
        margin-bottom: 60px;
        line-height: 1.5;
        font-weight: normal;
    }

    .gallery-section {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 auto;

        @include breakpoint($lg) {
            width: 85%;
        }

        @include breakpoint($xl) {
            width: 100%;
            justify-content: flex-start;
        }

        .gallery-item {
            width: 100%;
            height: 260px;
            position: relative;
            margin-bottom: 30px;
            display: flex;
            justify-content: center;

            @include breakpoint($md) {
                width: 44%;
            }

            @include breakpoint($lg) {
                width: 50%;
            }

            @include breakpoint($xl) {
                width: 33.333%;
                padding-right: 20px;
                box-sizing: border-box;
            }

            img {
                width: 360px;
                height: 260px;
                object-fit: cover;
            }

            &:before {
                position: absolute;
                content: '';
                width: 360px;
                height: 260px;
                transition: 1s;
            }

            &:after {
                position: absolute;
                content: '';
                width: 36px;
                height: 36px;
                transition: 1s;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                top: calc(50% - 18px);
                transition: 1s;

            }

            &:hover:before {
                background: rgba($ui-06, 0.8);
            }

            &:hover:after {
                background: url('../images/gallery-loop.png');
            }
        }
    }
}

/*.gallery {
    .title {
        font-size: 30px;
        color: $ui-09;
        width: fit-content;
        text-transform: uppercase;
        padding-bottom: 10px;
        border-bottom: 1px solid $ui-06;
        margin-bottom: 60px;
        line-height: 1.5;
        font-weight: normal;
    }

    .gallery-section {
        display: flex;
        flex-wrap: wrap;

        a {
            position: relative;
            margin: 0 10px;
            font-size: 0;

            &:hover {
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(223, 20, 20, 0.5);
                    background-image: url(../images/gallery-loop.png);
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            img {
                width: 360px;
                height: 260px;
                object-fit: cover;
            }
        }
    }
}*/