@import '../breakpoints.scss';
@import '../variables.scss';

.content-contact {
    &__title {
        font-size: 30px;
        color: $ui-09;
        width: fit-content;
        text-transform: uppercase;
        padding-bottom: 10px;
        border-bottom: 1px solid $ui-06;
        margin-bottom: 60px;
    }

    &__line {
        width: 100%;
        height: 1px;
        margin: 10px 0 40px;
        background-color: #cfcfcf;
    }

    &__boxes {
        display: flex;
        flex-wrap: wrap;

        .box {
            width: 100%;
            line-height: 1.9;
            margin-bottom: 30px;

            @include breakpoint($lg) {
                width: 33.3333%;
            }

            div {
                font-size: 16px;
                color: #424242;
                line-height: 1.4;

                &:first-of-type {
                    font-weight: bold;
                    margin: 0 0 10px;
                }
            }
        }
    }

    &__formbox {
        background-image: url(../images/contact-bg.png);
        background-repeat: no-repeat;
        background-position: right 150px top 100px;

        .form {
            display: flex;
            flex-direction: column;
            max-width: 570px;

            input[type=text], textarea {
                padding: 16px 15px;
                margin: 10px 0;
                text-align: center;
                border: 1px solid #cfcfcf;
            }

            textarea {
                min-height: 130px;
            }

            input[type=submit] {
                padding: 12px 21px;
                font-size: 14px;
                color: #fdfdfd;
                max-width: 185px;
                align-self: flex-end;
                background-color: #df1414;
                border: none;
                cursor: pointer;
            }

            &__group {
                display: flex;
                justify-content: space-between;
                input[type=text] {
                    width: 43%;
                }
            }

            label {
                font-size: 14px;
                margin: 5px 0 25px;

                a {
                    text-decoration: none;
                    color: #df1414;
                }
            }
        }
    }
}