@import 'breakpoints.scss';
@import 'variables.scss';

/*QUESTION FORM*/


.question-form {
    position: fixed;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    top: 0;
    z-index: 9999;
    transition: 5s;
    display: none;
    align-items: center;
    justify-items: center;

    &.active {
        display: flex;
    }

    .container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 1000;
        background: white;
        padding-top: 80px;
        padding-bottom: 80px;
        box-shadow: 1px 2px 5px 3px rgba(0, 0, 0, 0.2);
        border-radius: 10px;

        .close {
            position: absolute;
            font-size: 30px;
            right: 40px;
            top: 20px;
            font-weight: 500;
            color: #df1314;
            cursor: pointer;
        }

        .title {
            font-size: 20px;
            color: black;
            text-align: center;
            text-transform: uppercase;
            padding-bottom: 10px;
        }

        .msg {
            padding-bottom: 20px;
            color: red;
        }

        form {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;

            label {
                font-size: 12px;
            }

            input[type="text"] {
                max-width: 700px;
                width: 100%;
                height: 45px;
                border: 1px solid #d7d7d7;
                color: black;
                margin-bottom: 10px;
                box-sizing: border-box;
                padding-left: 15px;
                font-size: 14px;
                box-sizing: border-box;

                &::placeholder {
                    color: black;
                }

                border-radius: 10px;
                outline: none;
            }

            textarea {
                max-width: 700px;
                width: 100%;
                min-height: 95px;
                border: 1px solid #d7d7d7;
                color: black;
                margin-bottom: 30px;
                box-sizing: border-box;
                padding-left: 15px;
                font-size: 14px;
                padding-top: 15px;

                &::placeholder {
                    color: black;
                }

                border-radius: 10px;
                outline: none;
            }

            button {
                max-width: 500px;
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #df1314;
                border: none;
                color: white;
                font-size: 18px;
                margin-top: 50px;
                cursor: pointer;
            }
        }
    }
}

/*VISUALIZATION*/
.banner-vis {
    display: none;

    &.active {
        display: block;
    }

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

.vis-main {
    .svg-container {
        position: relative;
        margin: 10px 0;

        #svg {
            width: 100%;
            min-height: 300px;
            height: auto;

            &.load {
                background-image: url('../images/svg_loader.gif');
                background-repeat: no-repeat;
                background-position: center;
                background-size: 150px 150px;
            }

            svg {
                display: block;
                width: 100%;
                height: auto;
                margin: auto;
            }

            &.building.not-found {
                display: none;
            }

            &.floor.not-found {
                background-color: #b1b1b1;
                background-image: url('../images/svg_404.png');
                background-repeat: no-repeat;
                background-position: center;
            }

            &.floor {
                width: 60%;
                margin: auto;
            }
        }

        .svg-tooltip {
            display: none;
            position: absolute;
            left: 0;
            top: 0;
            text-align: center;
            padding: 5px 5px 10px 5px;
            font-size: 11px;
            color: white;
            background-color: black;

            @include breakpoint($lg) {
                font-size: 14px;
                padding: 20px;
            }

            &:after {
                content: '';
                position: absolute;
                width: 20px;
                height: 20px;
                left: calc(50% - 10px);
                bottom: -10px;
                background-color: black;
                transform: rotateZ(45deg);
            }

            span {
                color: #efb518;
            }

            .svg-click-info {
                display: none;

                @include breakpoint($lg) {
                    display: block;
                    margin: 10px 0 0;
                }
            }
        }
    }

    .vis-data {
        &.vis-building {
            .vis-object {
                fill: transparent;
                opacity: 0.8;
            }

            .vis-object:hover {
                fill: #8dc53d;
            }

            .vis-sold:hover {
                fill: #f74848;
            }

            .vis-disabled:hover {
                fill: transparent;
            }
        }

        &.vis-floor {
            .vis-object {
                fill: #8dc53d;
            }

            .vis-sold {
                fill: #f74848;
            }

            .vis-rent {
                fill: #fd8338;
            }

            .vis-disabled {
                fill: transparent;
            }

            .vis-number {
                fill: black;
            }
        }

        .vis-object:hover {
            cursor: pointer;
        }

        .vis-disabled:hover {
            cursor: default;
        }
    }

    .vis-labels {
        .vis-label-rect {
            fill: black;

            &.red {
                fill: #df1414;
            }
        }

        .vis-label-text {
            font-family: 'Work Sans', sans-serif;
            font-size: 22px;
            font-weight: bold;
            fill: white;
        }

        @media screen and (max-width: 991px) {
            .vis-label-rect {
                width: 920px;
                height: 50px;
            }

            .vis-label-longrect {
                width: 950px;
                height: 50px;
            }

            .vis-label-text {
                font-size: 25px;
            }
        }
    }

    /*FLOOR*/
    .floor-info {
        display: flex;
        flex-wrap: wrap;
        color: #414141;
        padding: 30px 40px;
        margin: 60px auto;
        background-color: #fafafa;

        .left-box {
            width: 100%;

            @include breakpoint($lg) {
                width: 50%;
            }

            .title {
                margin: 0;
                font-size: 30px;
                font-weight: bold;
            }

            .subtitle {
                font-size: 30px;
                font-weight: normal;
                text-transform: uppercase;
            }
        }

        .right-box {
            display: flex;
            flex-direction: column;
            width: 100%;

            @include breakpoint($lg) {
                width: 50%;
            }

            .legend {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;

                @include breakpoint($lg) {
                    justify-content: flex-end;
                }

                .legend-item {
                    position: relative;
                    font-size: 14px;
                    margin: 0 10px;
                    padding-left: 30px;
                    padding-top: 3px;
                    margin-top: 15px;

                    @include breakpoint($lg) {
                        margin-top: 0px;

                    }

                    &:before {
                        content: '';
                        position: absolute;
                        width: 23px;
                        height: 23px;
                        left: 0;
                        top: 0;
                    }

                    &.free:before {
                        background-color: #8dc53e;
                    }

                    &.rent:before {
                        background-color: #fd8338;
                    }

                    &.sold:before {
                        background-color: #f74848;
                    }
                }
            }

            .btn {
                margin-top: 30px;
                font-size: 16px;
                font-weight: bold;
                color: #ef1920;
                text-align: right;
                text-decoration: none;
            }
        }
    }
}