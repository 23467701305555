@import '../breakpoints.scss';
@import '../variables.scss';

.content-offer-list {

    .find-apartment {
        font-size: 30px;
        color: $ui-09;
        width: fit-content;
        text-align: center;
        text-transform: uppercase;
        position: relative;
        z-index: 1;
        margin-top: 80px;
        margin-bottom: 80px;
        margin-left: auto;
        margin-right: auto;

        span {
            color: $ui-06;
        }

        &:before {
            position: absolute;
            content: '';
            width: 164px;
            height: 164px;
            background: url('../images/icon-loop.png');
            right: -90px;
            top: -40px;
            z-index: -1;
            display: none;

            @include breakpoint($sm) {
                display: block;
            }
        }
    }

    .form-search {
        background: $ui-03;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
        z-index: 1;
        position: relative;

        @include breakpoint($sm) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        @include breakpoint($md) {
            align-items: center;
        }

        &__field {
            margin-bottom: 15px;

            &--select {
                max-width: 344px;
                width: 100%;

                @include breakpoint($sm) {
                    margin-right: 15px;
                }

                @include breakpoint($xl) {
                    width: 283px;
                }
            }

            &--input {
                width: 170px;
                display: flex;
                flex-wrap: wrap;
            }

            label {
                width: 100%;
                font-size: 14px;
                color: $ui-00;
                display: block;
                margin-bottom: 15px;
                text-transform: uppercase;
            }

            select {
                width: 100%;
                font-size: 14px;
                color: $ui-00;
                height: 33px;
                border: 1px solid $ui-07;
                background: transparent;

                option {
                    color: $ui-03;
                }
            }

            input {
                width: 70px;
                font-size: 14px;
                color: $ui-00;
                height: 33px;
                border: 1px solid $ui-07;
                background: transparent;
                margin-right: 10px;
                padding-left: 15px;
                box-sizing: border-box;
            }

        }

        &__submit {
            background: $ui-06;
            color: $ui-00;
            width: 174px;
            text-transform: uppercase;
            font-size: 14px;
            border: 0px;

            @include breakpoint($md) {
                margin-top: 15px;
            }
        }
    }

    &__title {
        font-size: 30px;
        color: $ui-09;
        width: fit-content;
        text-transform: uppercase;
        padding-bottom: 10px;
        border-bottom: 1px solid $ui-06;
        margin-bottom: 60px;
        margin-top: 60px;
    }

    &__text {
        font-size: 16px;
        font-weight: regular;
        color: $ui-09;
        line-height: 1.5;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    &__icons {
        display: flex;
        flex-wrap: wrap;

        .icons {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;

            @include breakpoint($sm) {
                width: 50%;
            }

            @include breakpoint($md) {
                width: 33.3%;
            }

            @include breakpoint($lg) {
                width: 16.6%;
            }

            &__img {
                width: 100px;
                height: 120px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__text {
                width: 125px;
                text-align: center;
            }
        }
    }

    table {
        width: 100%;

        th {
            font-size: 14px;
            font-weight: bold;
            background: $ui-12;
            cursor: pointer;
        }

        tr {
            width: 100%;
            height: 60px;
            font-size: 14px;
            line-height: 60px;
            color: $ui-09;
            border-bottom: 1px solid $ui-12;

            td {
                text-align: center;
                line-height: 60px;
                height: 60px;
            }
        }
    }

    &__video {
        width: 100%;
        height: 500px;
        border: 4px solid #d12230;
    }

    &__gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 auto;

        @include breakpoint($lg) {
            width: 85%;
        }

        @include breakpoint($xl) {
            width: 100%;
            justify-content: flex-start;
        }

        .gallery {
            width: 100%;
            height: 260px;
            position: relative;
            margin-bottom: 30px;
            display: flex;
            justify-content: center;

            @include breakpoint($md) {
                width: 44%;
            }

            @include breakpoint($lg) {
                width: 50%;
            }

            @include breakpoint($xl) {
                width: 33.333%;
                padding-right: 20px;
                box-sizing: border-box;
            }

            img {
                width: 360px;
                height: 260px;
            }

            &:before {
                position: absolute;
                content: '';
                width: 360px;
                height: 260px;
                transition: 1s;
            }

            &:after {
                position: absolute;
                content: '';
                width: 36px;
                height: 36px;
                transition: 1s;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                top: calc(50% - 18px);
                transition: 1s;

            }

            &:hover:before {
                background: rgba($ui-06, 0.8);
            }

            &:hover:after {
                background: url('../images/gallery-loop.png');
            }
        }
    }

    &__map {
        display: flex;
        flex-direction: column;

        @include breakpoint($lg) {
            flex-direction: row;
        }

        &--left {
            width: 100%;

            .google-maps {
                width: 100%;
                height: 400px;
                background: #d0d0d0;
                color: white;
            }
        }
        &--right {
            width: 100%;
            margin-top: 20px;

            @include breakpoint($lg) {
                max-width: 25%;
                padding-left: 40px;
                margin-top: 0;
            }
        }
    }
}