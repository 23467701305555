@import '../breakpoints.scss';
@import '../variables.scss';



.content-offer {
    &__title {
        font-size: 30px;
        color: $ui-09;
        width: fit-content;
        text-transform: uppercase;
        padding-bottom: 10px;
        border-bottom: 1px solid $ui-06;
        margin-bottom: 60px;
    }

    &__text {
        font-size: 16px;
        font-weight: regular;
        color: $ui-09;
        line-height: 1.5;
    }

    &__box {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint($lg) {
            flex-direction: unset;
            justify-content: space-between;
            margin: 20px 0;
        }

        .box {
            width: 360px;
            height: 300px;
            padding-left: 15px;
            padding-right: 15px;
            box-sizing: border-box;
            background-position: center !important;
            background-size: cover !important;
            margin-top: 35px;
            text-decoration: none;
            position: relative;

            @include breakpoint($lg) {
                width: 290px;
                height: 403px;
            }

            @include breakpoint($xl) {
                width: 360px;
                height: 473px;
            }

            &__city {
                font-size: 18px;
                color: $ui-00;
                z-index: 1;
                position: absolute;
                bottom: 35px;

                span {
                    width: 100%;
                    display: block;

                    &:first-child {
                        text-transform: uppercase;
                    }
                }
            }

            &:before {
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                width: 100%;
                height: 90px;
                background: rgba($ui-03, 0.8);
                transition: 1s;
            }

        }
    }
}