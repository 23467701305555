@import 'variables.scss';
$sm: (min: $screen-sm-min);
$md: (min: $screen-md-min);
$lg: (min: $screen-lg-min);
$xl: (min: $screen-xl-min);

@mixin breakpoint($map) {
	@media screen and (min-width: #{map-get($map, min)}) {
		@content;
	}
}

.container {
	width: 100%;
	padding-left: 15px;
	padding-right: 15px;
	box-sizing: border-box;
	margin: 0 auto;

	@include breakpoint($sm) {
		width: 540px;
	}

	@include breakpoint($md) {
		width: 720px;
	}

	@include breakpoint($lg) {
		width: 960px;
	}

	@include breakpoint($xl) {
		width: 1170px;
	}
}