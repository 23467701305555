@import '../breakpoints.scss';
@import '../variables.scss';


.menu-page {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px;
    width: 100%;
    margin-top: 50px;

    li {
        background-color: #373737;
        width: 32.3%;
        height: 53px;
        margin-right: 1px;
        margin-top: 1px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        transition: all 0.5s;
        padding-top: 5px;
        padding-bottom: 5px;

        &:hover {
            background-color: #df1414;
        }

        &.disabled {
            background-color: #737373;
            cursor: default;
        }

        div {
            display: none;
        }

        @include breakpoint($md) {
            width: 45%
        }

        @include breakpoint($lg) {
            width: 30%;

            div {
                display: block;
            }

            img {
                display: none;
            }
        }

        @include breakpoint($xl) {
            width: 189px;
        }

        &:last-child {
            margin-right: 0px;
        }

        a {
            color: #fdfdfd;
            font-size: 18px;
            font-weight: 400;
            text-align: center;
            height: 53px;
            line-height: 53px;
            text-transform: uppercase;
            display: block;
            width: 100%;
        }
    }
}

.content-offer-list {

    .find-apartment {
        font-size: 30px;
        color: $ui-09;
        width: fit-content;
        text-align: center;
        text-transform: uppercase;
        position: relative;
        z-index: 1;
        margin-top: 80px;
        margin-bottom: 80px;
        margin-left: auto;
        margin-right: auto;

        span {
            color: $ui-06;
        }

        &:before {
            position: absolute;
            content: '';
            width: 164px;
            height: 164px;
            background: url('../images/icon-loop.png');
            right: -90px;
            top: -40px;
            z-index: -1;
            display: none;

            @include breakpoint($sm) {
                display: block;
            }
        }
    }

    .form-search {
        background: $ui-03;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
        z-index: 1;
        position: relative;

        &--custom {
            margin-top: -80px;
        }

        @include breakpoint($sm) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        @include breakpoint($md) {
            align-items: center;
        }

        &__field {
            margin-bottom: 15px;

            &--select {
                max-width: 344px;
                width: 100%;

                @include breakpoint($sm) {
                    margin-right: 15px;
                }

                @include breakpoint($xl) {
                    width: 283px;
                }
            }

            &--input {
                width: 170px;
                display: flex;
                flex-wrap: wrap;
            }

            label {
                width: 100%;
                font-size: 14px;
                color: $ui-00;
                display: block;
                margin-bottom: 15px;
                text-transform: uppercase;
            }

            select {
                width: 100%;
                font-size: 14px;
                color: $ui-00;
                height: 33px;
                border: 1px solid $ui-07;
                background: transparent;

                option {
                    color: $ui-03;
                }
            }

            input {
                width: 70px;
                font-size: 14px;
                color: $ui-00;
                height: 33px;
                border: 1px solid $ui-07;
                background: transparent;
                margin-right: 10px;
                padding-left: 15px;
                box-sizing: border-box;

                &::placeholder {
                    color: $ui-00;
                }
            }

        }

        &__submit {
            background: $ui-06;
            color: $ui-00;
            width: 174px;
            text-transform: uppercase;
            font-size: 14px;
            border: 0px;

            @include breakpoint($md) {
                margin-top: 15px;
            }
        }
    }

    &__title {
        font-size: 30px;
        color: $ui-09;
        width: fit-content;
        text-transform: uppercase;
        padding-bottom: 10px;
        border-bottom: 1px solid $ui-06;
        margin-bottom: 60px;
        margin-top: 60px;
    }

    &__text {
        font-size: 16px;
        font-weight: regular;
        color: $ui-09;
        line-height: 1.5;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    &__icons {
        display: flex;
        flex-wrap: wrap;

        .icons {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;

            @include breakpoint($sm) {
                width: 50%;
            }

            @include breakpoint($md) {
                width: 33.3%;
            }

            @include breakpoint($lg) {
                width: 16.6%;
            }

            &__img {
                width: 100px;
                height: 120px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            &__text {
                width: 125px;
                text-align: center;
            }
        }
    }

    .apartments-header {
        display: flex;
        flex-direction: column;
        margin: 60px 0;

        @include breakpoint($lg) {
            flex-direction: row;
            justify-content: space-between;
        }

        .content-offer-list__title {
            margin: 0;
        }
        
        .apartments-stats {
            display: flex;
            flex-direction: column;
            padding: 25px 10px;
            margin-top: 20px;
            border: 1px solid #d7d7d7;

            @include breakpoint($lg) {
                flex-direction: row;
                margin-top: 0;
            }

            &__item {
                font-size: 18px;
                padding: 3px 0;

                @include breakpoint($lg) {
                    padding: 0 10px;
                }
                
                &.sold {
                    color: #ef1920;
                }

                &.rez {
                    color: #f4862e;
                }

                &.free {
                    color: #2cab00;
                }
            }
        }
    }

    table {
        width: 100%;

        th {
            font-size: 14px;
            font-weight: bold;
            background: $ui-12;
            cursor: pointer;
        }

        tr {
            width: 100%;
            height: 60px;
            font-size: 14px;
            line-height: 60px;
            color: $ui-09;
            border-bottom: 1px solid $ui-12;

            &.rez {
                color: #f67c3f;
            }

            td {
                text-align: center;
                line-height: 60px;
                height: 60px;
            }
        }
    }

    &__video {
        width: 100%;
        height: 500px;
        border: 4px solid #d12230;
    }

    &__gallery {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: 0 auto;

        @include breakpoint($lg) {
            width: 85%;
        }

        @include breakpoint($xl) {
            width: 100%;
            justify-content: flex-start;
        }

        .gallery {
            width: 100%;
            height: 260px;
            position: relative;
            margin-bottom: 30px;
            display: flex;
            justify-content: center;

            @include breakpoint($md) {
                width: 44%;
            }

            @include breakpoint($lg) {
                width: 50%;
            }

            @include breakpoint($xl) {
                width: 33.333%;
                padding-right: 20px;
                box-sizing: border-box;
            }

            img {
                width: 360px;
                height: 260px;
                object-fit: cover;
            }

            &:before {
                position: absolute;
                content: '';
                width: 360px;
                height: 260px;
                transition: 1s;
            }

            &:after {
                position: absolute;
                content: '';
                width: 36px;
                height: 36px;
                transition: 1s;
                left: 0;
                right: 0;
                margin-left: auto;
                margin-right: auto;
                top: calc(50% - 18px);
                transition: 1s;

            }

            &:hover:before {
                background: rgba($ui-06, 0.8);
            }

            &:hover:after {
                background: url('../images/gallery-loop.png');
            }
        }
    }
}