@import '../breakpoints.scss';
@import '../variables.scss';

.slider-wrapper {
    height: auto;

    .mainSlider {
        width: 100%;
        height: 700px;
        position: relative;

        .sliders-number {
            position: absolute;
            bottom: 155px;
            display: flex;
            list-style: none;
            margin: 0 auto;
            left: 0;
            right: 0;
            width: fit-content;

            li {
                width: 11px;
                height: 11px;
                border: 5px solid #737c84;
                margin: 5px;

                &.slick-active {
                    border-color: white;
                    background: #df1414;

                }

                button {
                    display: none;
                }
            }
        }

        .pic {
            height: 700px !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
            background-size: cover;
            display: flex !important;
            align-items: center;
            justify-content: center;

            &__content {
                margin-top: -130px;
                padding-left: 15px;
                padding-right: 15px;
                box-sizing: border-box;

                .text {
                    color: $ui-00;
                    font-size: 20px;
                    font-weight: 700;
                    text-align: center;
                    text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.4);
                    max-width: 1004px;
                    width: 100%;
                    margin: 20px auto;

                    @include breakpoint($md) {
                        font-size: 48px;
                        margin: 0 auto;
                    }
                }

                .text-small {
                    background-color: #df1414;
                    max-width: 1108px;
                    width: 100%;
                    text-align: center;
                    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
                    font-weight: 400;
                    font-size: 16px;
                    color: $ui-00;
                    margin: 0 auto 0 auto;
                    padding-top: 20px;
                    padding-bottom: 20px;

                    @include breakpoint($md) {
                        margin: -25px auto 0 auto;
                        font-size: 24px;
                    }
                }
            }
        }
    }

    .video {
        width: 100%;
        height: auto;
        position: relative;

        &__input {
            height: 700px;
        }

        &__clicker {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            background: none;
            border: none;

            &:focus {
                outline: 0;
            }

            #changeImage {
                display: inline-block;
                width: 81px;
                height: 81px;
                background: url(../images/play.png);
            }

            &--active {
                bottom: 0;
                transition: 1s;

                #changeImage {
                    background: transparent;
                }

                &:hover {
                    #changeImage {

                        background: url(../images/stop.png);

                    }
                }
            }
        }
    }

    .banner {
        width: 100%;
        height: 330px;
        background-position: center !important;
        background-repeat: no-repeat !important;
        margin-bottom: 60px;

        &--svg {
            height: 930px;
        }
    }
}