@charset "UTF-8";
.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

html {
  scroll-behavior: smooth; }

body {
  font-family: 'Work Sans', sans-serif;
  font-weight: 400;
  margin: 0;
  overflow-x: hidden; }

h2 {
  font-weight: normal; }

/*DOCS CONTAINER*/
.doc-container {
  width: 100%; }
  .doc-container.container {
    padding-top: 50px; }
  .doc-container__box {
    width: 100%; }
    .doc-container__box__title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 75px;
      padding-left: 30px;
      padding-right: 100px;
      font-size: 24px;
      text-transform: uppercase;
      color: white;
      background: #df1314;
      box-sizing: border-box;
      cursor: pointer; }
    .doc-container__box__list {
      display: none; }
      .doc-container__box__list__item {
        height: 75px;
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 30px;
        background: white;
        color: black;
        font-size: 18px;
        text-transform: uppercase;
        justify-content: space-between;
        padding-right: 100px;
        box-sizing: border-box;
        text-decoration: none; }
        .doc-container__box__list__item:nth-of-type(2n+2) {
          background: #f3f3f3; }

/*AJAX LOADER*/
.ajax-loader {
  display: none;
  color: black;
  font-size: 20px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 5px auto 25px;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease; }
  .ajax-loader.active {
    display: block; }

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em; }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em; }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em; }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; } }

@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em; }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em; }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em; }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em; } }

@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin: 0 auto; }
  @media screen and (min-width: 576px) {
    .container {
      width: 540px; } }
  @media screen and (min-width: 768px) {
    .container {
      width: 720px; } }
  @media screen and (min-width: 992px) {
    .container {
      width: 960px; } }
  @media screen and (min-width: 1200px) {
    .container {
      width: 1170px; } }

.button {
  height: 33px;
  display: flex;
  justify-content: center;
  align-items: center; }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin: 0 auto; }
  @media screen and (min-width: 576px) {
    .container {
      width: 540px; } }
  @media screen and (min-width: 768px) {
    .container {
      width: 720px; } }
  @media screen and (min-width: 992px) {
    .container {
      width: 960px; } }
  @media screen and (min-width: 1200px) {
    .container {
      width: 1170px; } }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

/*QUESTION FORM*/
.question-form {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 5s;
  display: none;
  align-items: center;
  justify-items: center; }
  .question-form.active {
    display: flex; }
  .question-form .container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    z-index: 1000;
    background: white;
    padding-top: 80px;
    padding-bottom: 80px;
    box-shadow: 1px 2px 5px 3px rgba(0, 0, 0, 0.2);
    border-radius: 10px; }
    .question-form .container .close {
      position: absolute;
      font-size: 30px;
      right: 40px;
      top: 20px;
      font-weight: 500;
      color: #df1314;
      cursor: pointer; }
    .question-form .container .title {
      font-size: 20px;
      color: black;
      text-align: center;
      text-transform: uppercase;
      padding-bottom: 10px; }
    .question-form .container .msg {
      padding-bottom: 20px;
      color: red; }
    .question-form .container form {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      width: 100%; }
      .question-form .container form label {
        font-size: 12px; }
      .question-form .container form input[type="text"] {
        max-width: 700px;
        width: 100%;
        height: 45px;
        border: 1px solid #d7d7d7;
        color: black;
        margin-bottom: 10px;
        box-sizing: border-box;
        padding-left: 15px;
        font-size: 14px;
        box-sizing: border-box;
        border-radius: 10px;
        outline: none; }
        .question-form .container form input[type="text"]::placeholder {
          color: black; }
      .question-form .container form textarea {
        max-width: 700px;
        width: 100%;
        min-height: 95px;
        border: 1px solid #d7d7d7;
        color: black;
        margin-bottom: 30px;
        box-sizing: border-box;
        padding-left: 15px;
        font-size: 14px;
        padding-top: 15px;
        border-radius: 10px;
        outline: none; }
        .question-form .container form textarea::placeholder {
          color: black; }
      .question-form .container form button {
        max-width: 500px;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #df1314;
        border: none;
        color: white;
        font-size: 18px;
        margin-top: 50px;
        cursor: pointer; }

/*VISUALIZATION*/
.banner-vis {
  display: none; }
  .banner-vis.active {
    display: block; }
  .banner-vis img {
    width: 100%;
    height: auto;
    object-fit: cover; }

.vis-main {
  /*FLOOR*/ }
  .vis-main .svg-container {
    position: relative;
    margin: 10px 0; }
    .vis-main .svg-container #svg {
      width: 100%;
      min-height: 300px;
      height: auto; }
      .vis-main .svg-container #svg.load {
        background-image: url("../images/svg_loader.gif");
        background-repeat: no-repeat;
        background-position: center;
        background-size: 150px 150px; }
      .vis-main .svg-container #svg svg {
        display: block;
        width: 100%;
        height: auto;
        margin: auto; }
      .vis-main .svg-container #svg.building.not-found {
        display: none; }
      .vis-main .svg-container #svg.floor.not-found {
        background-color: #b1b1b1;
        background-image: url("../images/svg_404.png");
        background-repeat: no-repeat;
        background-position: center; }
      .vis-main .svg-container #svg.floor {
        width: 60%;
        margin: auto; }
    .vis-main .svg-container .svg-tooltip {
      display: none;
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;
      padding: 5px 5px 10px 5px;
      font-size: 11px;
      color: white;
      background-color: black; }
      @media screen and (min-width: 992px) {
        .vis-main .svg-container .svg-tooltip {
          font-size: 14px;
          padding: 20px; } }
      .vis-main .svg-container .svg-tooltip:after {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        left: calc(50% - 10px);
        bottom: -10px;
        background-color: black;
        transform: rotateZ(45deg); }
      .vis-main .svg-container .svg-tooltip span {
        color: #efb518; }
      .vis-main .svg-container .svg-tooltip .svg-click-info {
        display: none; }
        @media screen and (min-width: 992px) {
          .vis-main .svg-container .svg-tooltip .svg-click-info {
            display: block;
            margin: 10px 0 0; } }
  .vis-main .vis-data.vis-building .vis-object {
    fill: transparent;
    opacity: 0.8; }
  .vis-main .vis-data.vis-building .vis-object:hover {
    fill: #8dc53d; }
  .vis-main .vis-data.vis-building .vis-sold:hover {
    fill: #f74848; }
  .vis-main .vis-data.vis-building .vis-disabled:hover {
    fill: transparent; }
  .vis-main .vis-data.vis-floor .vis-object {
    fill: #8dc53d; }
  .vis-main .vis-data.vis-floor .vis-sold {
    fill: #f74848; }
  .vis-main .vis-data.vis-floor .vis-rent {
    fill: #fd8338; }
  .vis-main .vis-data.vis-floor .vis-disabled {
    fill: transparent; }
  .vis-main .vis-data.vis-floor .vis-number {
    fill: black; }
  .vis-main .vis-data .vis-object:hover {
    cursor: pointer; }
  .vis-main .vis-data .vis-disabled:hover {
    cursor: default; }
  .vis-main .vis-labels .vis-label-rect {
    fill: black; }
    .vis-main .vis-labels .vis-label-rect.red {
      fill: #df1414; }
  .vis-main .vis-labels .vis-label-text {
    font-family: 'Work Sans', sans-serif;
    font-size: 22px;
    font-weight: bold;
    fill: white; }
  @media screen and (max-width: 991px) {
    .vis-main .vis-labels .vis-label-rect {
      width: 920px;
      height: 50px; }
    .vis-main .vis-labels .vis-label-longrect {
      width: 950px;
      height: 50px; }
    .vis-main .vis-labels .vis-label-text {
      font-size: 25px; } }
  .vis-main .floor-info {
    display: flex;
    flex-wrap: wrap;
    color: #414141;
    padding: 30px 40px;
    margin: 60px auto;
    background-color: #fafafa; }
    .vis-main .floor-info .left-box {
      width: 100%; }
      @media screen and (min-width: 992px) {
        .vis-main .floor-info .left-box {
          width: 50%; } }
      .vis-main .floor-info .left-box .title {
        margin: 0;
        font-size: 30px;
        font-weight: bold; }
      .vis-main .floor-info .left-box .subtitle {
        font-size: 30px;
        font-weight: normal;
        text-transform: uppercase; }
    .vis-main .floor-info .right-box {
      display: flex;
      flex-direction: column;
      width: 100%; }
      @media screen and (min-width: 992px) {
        .vis-main .floor-info .right-box {
          width: 50%; } }
      .vis-main .floor-info .right-box .legend {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start; }
        @media screen and (min-width: 992px) {
          .vis-main .floor-info .right-box .legend {
            justify-content: flex-end; } }
        .vis-main .floor-info .right-box .legend .legend-item {
          position: relative;
          font-size: 14px;
          margin: 0 10px;
          padding-left: 30px;
          padding-top: 3px;
          margin-top: 15px; }
          @media screen and (min-width: 992px) {
            .vis-main .floor-info .right-box .legend .legend-item {
              margin-top: 0px; } }
          .vis-main .floor-info .right-box .legend .legend-item:before {
            content: '';
            position: absolute;
            width: 23px;
            height: 23px;
            left: 0;
            top: 0; }
          .vis-main .floor-info .right-box .legend .legend-item.free:before {
            background-color: #8dc53e; }
          .vis-main .floor-info .right-box .legend .legend-item.rent:before {
            background-color: #fd8338; }
          .vis-main .floor-info .right-box .legend .legend-item.sold:before {
            background-color: #f74848; }
      .vis-main .floor-info .right-box .btn {
        margin-top: 30px;
        font-size: 16px;
        font-weight: bold;
        color: #ef1920;
        text-align: right;
        text-decoration: none; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin: 0 auto; }
  @media screen and (min-width: 576px) {
    .container {
      width: 540px; } }
  @media screen and (min-width: 768px) {
    .container {
      width: 720px; } }
  @media screen and (min-width: 992px) {
    .container {
      width: 960px; } }
  @media screen and (min-width: 1200px) {
    .container {
      width: 1170px; } }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.header {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  width: 100%;
  padding: 15px;
  box-sizing: border-box; }
  @media screen and (min-width: 992px) {
    .header {
      max-width: 1200px;
      margin: 0 auto; } }
  .header__text {
    display: none;
    font-size: 14px;
    color: #000000;
    margin-right: 15px; }
    @media screen and (min-width: 576px) {
      .header__text {
        display: flex; } }
    .header__text:nth-of-type(3) {
      margin-right: 30px; }
    .header__text span {
      display: block;
      color: #939393;
      margin-right: 5px; }
    .header__text a {
      color: inherit; }
      .header__text a:hover {
        text-decoration: underline; }
  .header__languages {
    display: none; }
    @media screen and (min-width: 992px) {
      .header__languages {
        display: block; } }
    .header__languages .languages {
      display: flex;
      align-items: center;
      cursor: pointer; }
      .header__languages .languages--click {
        position: relative;
        z-index: 1;
        background: transparent;
        border: 0px;
        outline: none; }
      .header__languages .languages img:first-child {
        margin-right: 5px; }
      .header__languages .languages--list {
        position: relative;
        z-index: 10000;
        flex-direction: column;
        padding-left: 8px;
        margin-top: 10px;
        opacity: 0;
        background: white;
        transition: 1s;
        visibility: hidden; }
        .header__languages .languages--list.show {
          opacity: 1;
          visibility: visible; }
        .header__languages .languages--list a {
          padding: 5px; }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin: 0 auto; }
  @media screen and (min-width: 576px) {
    .container {
      width: 540px; } }
  @media screen and (min-width: 768px) {
    .container {
      width: 720px; } }
  @media screen and (min-width: 992px) {
    .container {
      width: 960px; } }
  @media screen and (min-width: 1200px) {
    .container {
      width: 1170px; } }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.slider-wrapper {
  height: auto; }
  .slider-wrapper .mainSlider {
    width: 100%;
    height: 700px;
    position: relative; }
    .slider-wrapper .mainSlider .sliders-number {
      position: absolute;
      bottom: 155px;
      display: flex;
      list-style: none;
      margin: 0 auto;
      left: 0;
      right: 0;
      width: fit-content; }
      .slider-wrapper .mainSlider .sliders-number li {
        width: 11px;
        height: 11px;
        border: 5px solid #737c84;
        margin: 5px; }
        .slider-wrapper .mainSlider .sliders-number li.slick-active {
          border-color: white;
          background: #df1414; }
        .slider-wrapper .mainSlider .sliders-number li button {
          display: none; }
    .slider-wrapper .mainSlider .pic {
      height: 700px !important;
      background-position: center !important;
      background-repeat: no-repeat !important;
      background-size: cover;
      display: flex !important;
      align-items: center;
      justify-content: center; }
      .slider-wrapper .mainSlider .pic__content {
        margin-top: -130px;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box; }
        .slider-wrapper .mainSlider .pic__content .text {
          color: white;
          font-size: 20px;
          font-weight: 700;
          text-align: center;
          text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.4);
          max-width: 1004px;
          width: 100%;
          margin: 20px auto; }
          @media screen and (min-width: 768px) {
            .slider-wrapper .mainSlider .pic__content .text {
              font-size: 48px;
              margin: 0 auto; } }
        .slider-wrapper .mainSlider .pic__content .text-small {
          background-color: #df1414;
          max-width: 1108px;
          width: 100%;
          text-align: center;
          text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.4);
          font-weight: 400;
          font-size: 16px;
          color: white;
          margin: 0 auto 0 auto;
          padding-top: 20px;
          padding-bottom: 20px; }
          @media screen and (min-width: 768px) {
            .slider-wrapper .mainSlider .pic__content .text-small {
              margin: -25px auto 0 auto;
              font-size: 24px; } }
  .slider-wrapper .video {
    width: 100%;
    height: auto;
    position: relative; }
    .slider-wrapper .video__input {
      height: 700px; }
    .slider-wrapper .video__clicker {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      background: none;
      border: none; }
      .slider-wrapper .video__clicker:focus {
        outline: 0; }
      .slider-wrapper .video__clicker #changeImage {
        display: inline-block;
        width: 81px;
        height: 81px;
        background: url(../images/play.png); }
      .slider-wrapper .video__clicker--active {
        bottom: 0;
        transition: 1s; }
        .slider-wrapper .video__clicker--active #changeImage {
          background: transparent; }
        .slider-wrapper .video__clicker--active:hover #changeImage {
          background: url(../images/stop.png); }
  .slider-wrapper .banner {
    width: 100%;
    height: 330px;
    background-position: center !important;
    background-repeat: no-repeat !important;
    margin-bottom: 60px; }
    .slider-wrapper .banner--svg {
      height: 930px; }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin: 0 auto; }
  @media screen and (min-width: 576px) {
    .container {
      width: 540px; } }
  @media screen and (min-width: 768px) {
    .container {
      width: 720px; } }
  @media screen and (min-width: 992px) {
    .container {
      width: 960px; } }
  @media screen and (min-width: 1200px) {
    .container {
      width: 1170px; } }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.rwd-flags {
  margin-left: 10px;
  display: flex; }
  @media screen and (min-width: 992px) {
    .rwd-flags {
      display: none; } }
  .rwd-flags a {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px; }

.nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
  margin-top: 20px; }
  @media screen and (min-width: 992px) {
    .nav {
      margin-top: -70px;
      justify-content: space-between; } }
  .nav__hamburger {
    width: 50px;
    background: transparent;
    border: 0px;
    padding-bottom: 15px;
    z-index: 3;
    transition: 1s;
    position: absolute;
    top: 15px;
    outline: none;
    right: 20px; }
    @media screen and (min-width: 992px) {
      .nav__hamburger {
        display: none; } }
    .nav__hamburger div {
      width: 100%;
      height: 5px;
      margin-bottom: 6px;
      background: #ef1920;
      transition: 1s; }
      .nav__hamburger div:last-child {
        margin-bottom: 0px; }
    .nav__hamburger.close {
      top: 60px;
      z-index: 100000;
      position: fixed;
      right: 20px; }
      .nav__hamburger.close div {
        transform: rotate(-45deg); }
        .nav__hamburger.close div:nth-of-type(2) {
          transform: rotate(45deg);
          margin-top: -10px; }
        .nav__hamburger.close div:nth-of-type(3) {
          opacity: 0;
          transform: rotate(0deg); }
  .nav__menu {
    position: fixed;
    background: white;
    width: 300px;
    height: 100vh;
    right: 0;
    top: -15px;
    text-indent: 30px;
    list-style: none;
    padding-top: 50px;
    box-sizing: border-box;
    transform: translate(300px);
    transition: 1s;
    z-index: 9999;
    overflow: auto; }
    .nav__menu.show {
      transform: translate(0px); }
    @media screen and (min-width: 992px) {
      .nav__menu {
        position: static;
        transform: translate(0px);
        opacity: 1;
        display: flex;
        background: transparent;
        width: max-content;
        height: auto;
        text-indent: 0px;
        padding-top: 0px;
        overflow: inherit; } }
    .nav__menu .menu {
      width: 100%; }
      @media screen and (min-width: 992px) {
        .nav__menu .menu {
          padding-right: 15px;
          width: fit-content;
          position: relative;
          transition: all 0.3s; }
          .nav__menu .menu:last-child {
            padding-right: 0px; } }
      .nav__menu .menu__item {
        padding-top: 20px;
        font-size: 16px;
        font-weight: 400;
        color: #414141;
        text-transform: uppercase;
        text-decoration: none;
        width: 100%;
        display: block;
        padding-bottom: 10px;
        box-sizing: border-box; }
        .nav__menu .menu__item--active {
          color: #ef1920;
          border-bottom: 3px solid #ef1920;
          padding-bottom: 7px; }
        .nav__menu .menu__item:hover {
          color: #ef1920;
          border-bottom: 3px solid #ef1920;
          padding-bottom: 7px; }
        @media screen and (min-width: 992px) {
          .nav__menu .menu__item {
            width: fit-content; } }
      .nav__menu .menu .child {
        background: white;
        list-style: none;
        margin-top: 20px;
        margin-bottom: 20px;
        transition: all 0.3s; }
        @media screen and (min-width: 992px) {
          .nav__menu .menu .child {
            position: absolute;
            min-width: 300px;
            text-indent: 5px;
            padding-bottom: 20px;
            display: none;
            margin-top: 0px;
            padding-top: 30px; } }
        .nav__menu .menu .child li {
          margin-bottom: 15px; }
          .nav__menu .menu .child li a {
            color: #414141;
            padding-top: 20px;
            padding-bottom: 20px;
            width: 100%;
            display: block; }
            .nav__menu .menu .child li a:hover {
              color: #ef1920; }
      @media screen and (min-width: 992px) {
        .nav__menu .menu:hover .child {
          display: block; } }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin: 0 auto; }
  @media screen and (min-width: 576px) {
    .container {
      width: 540px; } }
  @media screen and (min-width: 768px) {
    .container {
      width: 720px; } }
  @media screen and (min-width: 992px) {
    .container {
      width: 960px; } }
  @media screen and (min-width: 1200px) {
    .container {
      width: 1170px; } }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.main .projects {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media screen and (min-width: 992px) {
    .main .projects {
      flex-direction: unset;
      justify-content: space-between;
      margin-top: -152px; } }
  .main .projects .box {
    width: 360px;
    height: 300px;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    background-position: center !important;
    background-size: cover !important;
    margin-top: 35px;
    text-decoration: none;
    position: relative; }
    @media screen and (min-width: 992px) {
      .main .projects .box {
        width: 290px;
        height: 403px; } }
    @media screen and (min-width: 1200px) {
      .main .projects .box {
        width: 360px;
        height: 473px; } }
    @media screen and (min-width: 992px) {
      .main .projects .box:hover .box__text {
        opacity: 1; }
      .main .projects .box:hover:before {
        height: 100%; } }
    .main .projects .box__text {
      width: 100%;
      font-size: 16px;
      font-weight: regular;
      color: white;
      padding-top: 60px;
      z-index: 1;
      position: relative;
      opacity: 0;
      transition: 1.8s; }
      .main .projects .box__text span {
        font-size: 18px;
        padding-top: 20px;
        display: block; }
    .main .projects .box__city {
      font-size: 17px;
      color: white;
      z-index: 1;
      position: absolute;
      bottom: 25px; }
      .main .projects .box__city span {
        width: 100%;
        display: block; }
        .main .projects .box__city span:first-child {
          text-transform: uppercase; }
    .main .projects .box:before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 100%;
      height: 90px;
      background: rgba(0, 0, 0, 0.8);
      transition: 0.5s; }

.main .find-apartment {
  font-size: 30px;
  color: #424242;
  width: fit-content;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  margin-top: 60px;
  margin-bottom: 70px;
  margin-left: auto;
  margin-right: auto; }
  .main .find-apartment span {
    color: #DF1414; }
  .main .find-apartment:before {
    position: absolute;
    content: '';
    width: 164px;
    height: 164px;
    background: url("../images/icon-loop.png");
    right: -90px;
    top: -40px;
    z-index: -1;
    display: none; }
    @media screen and (min-width: 576px) {
      .main .find-apartment:before {
        display: block; } }

.main .form-search {
  background: #000000;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  z-index: 1;
  position: relative; }
  @media screen and (min-width: 576px) {
    .main .form-search {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; } }
  @media screen and (min-width: 768px) {
    .main .form-search {
      align-items: center; } }
  .main .form-search__field {
    margin-bottom: 15px; }
    .main .form-search__field--select {
      max-width: 344px;
      width: 100%; }
      @media screen and (min-width: 576px) {
        .main .form-search__field--select {
          margin-right: 15px; } }
      @media screen and (min-width: 1200px) {
        .main .form-search__field--select {
          width: 283px; } }
    .main .form-search__field--input {
      width: 170px;
      display: flex;
      flex-wrap: wrap; }
    .main .form-search__field label {
      width: 100%;
      font-size: 14px;
      color: white;
      display: block;
      margin-bottom: 15px;
      text-transform: uppercase; }
    .main .form-search__field select {
      width: 100%;
      font-size: 14px;
      color: white;
      height: 33px;
      border: 1px solid #575757;
      background: transparent; }
      .main .form-search__field select option {
        color: #000000; }
    .main .form-search__field input {
      width: 70px;
      font-size: 14px;
      color: white;
      height: 33px;
      border: 1px solid #575757;
      background: transparent;
      margin-right: 10px;
      padding-left: 15px;
      box-sizing: border-box; }
      .main .form-search__field input::placeholder {
        color: white; }
  .main .form-search__submit {
    background: #DF1414;
    color: white;
    width: 174px;
    text-transform: uppercase;
    font-size: 14px;
    border: 0px; }
    @media screen and (min-width: 768px) {
      .main .form-search__submit {
        margin-top: 15px; } }

.main .wrapper-background {
  background: #F7F7F7;
  padding-top: 135px;
  padding-bottom: 80px; }
  @media screen and (min-width: 1200px) {
    .main .wrapper-background {
      margin-top: -65px; } }
  .main .wrapper-background .about {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    @media screen and (min-width: 768px) {
      .main .wrapper-background .about {
        justify-content: flex-start; } }
    .main .wrapper-background .about .box {
      width: 293px;
      height: 293px; }
      @media screen and (min-width: 992px) {
        .main .wrapper-background .about .box {
          border: 0.5px solid #B7B7B7 !important;
          width: 283px;
          height: 283px; } }
      .main .wrapper-background .about .box:nth-of-type(9) {
        border-bottom: 1px solid #B7B7B7; }
      @media screen and (min-width: 768px) {
        .main .wrapper-background .about .box--developer {
          width: 589px; } }
      @media screen and (min-width: 992px) {
        .main .wrapper-background .about .box--developer {
          border: 0px !important;
          width: 567px; } }
      @media screen and (min-width: 1200px) {
        .main .wrapper-background .about .box--developer {
          width: 567px; } }
      .main .wrapper-background .about .box--history {
        border: 1px solid #B7B7B7;
        border-bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; }
        .main .wrapper-background .about .box--history div {
          width: 100%;
          color: #424242;
          font-size: 18px;
          text-align: center; }
          .main .wrapper-background .about .box--history div:nth-of-type(1) {
            color: #DF1414;
            font-size: 60px;
            font-weight: bold;
            margin-bottom: 25px; }
          .main .wrapper-background .about .box--history div:last-child {
            margin-bottom: 0px; }
        .main .wrapper-background .about .box--history:nth-child(2) {
          background: red; }
      .main .wrapper-background .about .box--img {
        display: flex;
        justify-content: center;
        align-items: center; }
        @media screen and (min-width: 992px) {
          .main .wrapper-background .about .box--img img {
            width: 283px;
            height: 283px;
            object-fit: scale-down; } }
        @media screen and (min-width: 1200px) {
          .main .wrapper-background .about .box--img img {
            width: 283px;
            height: 283px;
            object-fit: scale-down; } }
      .main .wrapper-background .about .box__title {
        font-size: 20px;
        color: #424242;
        width: fit-content;
        text-transform: uppercase;
        padding-bottom: 10px;
        border-bottom: 1px solid #DF1414;
        margin-bottom: 50px; }
        @media screen and (min-width: 768px) {
          .main .wrapper-background .about .box__title {
            font-size: 30px; } }
      .main .wrapper-background .about .box__text {
        font-size: 28px;
        color: #424242;
        width: fit-content;
        text-transform: uppercase;
        line-height: 1.5; }
        @media screen and (min-width: 768px) {
          .main .wrapper-background .about .box__text {
            font-size: 48px; } }
        .main .wrapper-background .about .box__text span {
          width: 100%;
          display: block;
          font-weight: bold; }

.main .wrapper-background-red {
  background: linear-gradient(180deg, #DF1414 65%, #fff 35%); }
  @media screen and (min-width: 576px) {
    .main .wrapper-background-red {
      background: linear-gradient(180deg, #DF1414 50%, #fff 50%); } }
  @media screen and (min-width: 1200px) {
    .main .wrapper-background-red {
      background: linear-gradient(90deg, #DF1414 50%, #fff 50%);
      padding-top: 60px;
      padding-bottom: 60px; } }

@media screen and (min-width: 1200px) {
  .main .about-us {
    display: flex; } }

.main .about-us .icons {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media screen and (min-width: 1200px) {
    .main .about-us .icons {
      width: 50%;
      padding-top: 0px;
      padding-bottom: 0px; } }
  .main .about-us .icons__title {
    font-size: 30px;
    color: white;
    width: fit-content;
    text-transform: uppercase;
    padding-bottom: 10px;
    border-bottom: 2px solid white;
    margin-bottom: 60px; }
  .main .about-us .icons .icons__box {
    width: 100%;
    display: flex;
    flex-wrap: wrap; }
    @media screen and (min-width: 1200px) {
      .main .about-us .icons .icons__box {
        width: 100%; } }
    .main .about-us .icons .icons__box .box {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 40px; }
      .main .about-us .icons .icons__box .box:last-child {
        margin-bottom: 0px; }
      @media screen and (min-width: 576px) {
        .main .about-us .icons .icons__box .box {
          width: 33.3%; }
          .main .about-us .icons .icons__box .box:nth-of-type(4), .main .about-us .icons .icons__box .box:nth-of-type(5) {
            margin: 0px; } }
      .main .about-us .icons .icons__box .box__img {
        width: 75px;
        height: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px; }
      .main .about-us .icons .icons__box .box__text {
        font-size: 18px;
        color: white;
        width: 140px;
        text-align: center; }

.main .about-us .developer {
  width: 100%;
  padding-top: 60px;
  padding-bottom: 60px; }
  @media screen and (min-width: 1200px) {
    .main .about-us .developer {
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 100px;
      box-sizing: border-box;
      width: 50%; } }
  .main .about-us .developer__title {
    font-size: 30px;
    color: #424242;
    width: fit-content;
    text-transform: uppercase;
    padding-bottom: 10px;
    margin-bottom: 60px; }
  .main .about-us .developer__text {
    font-size: 16px;
    color: #424242;
    width: 100%;
    max-width: 456px;
    text-align: left; }
  .main .about-us .developer__more {
    background: #DF1414;
    color: white;
    width: 188px;
    text-transform: uppercase;
    font-size: 14px;
    border: 0px;
    text-decoration: none;
    margin-top: 40px; }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin: 0 auto; }
  @media screen and (min-width: 576px) {
    .container {
      width: 540px; } }
  @media screen and (min-width: 768px) {
    .container {
      width: 720px; } }
  @media screen and (min-width: 992px) {
    .container {
      width: 960px; } }
  @media screen and (min-width: 1200px) {
    .container {
      width: 1170px; } }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

footer .google-maps {
  width: 100%;
  height: 629px; }

footer .visit {
  padding-top: 40px; }
  @media screen and (min-width: 992px) {
    footer .visit {
      display: flex; } }
  footer .visit .side {
    margin-bottom: 40px;
    width: 100%; }
    @media screen and (min-width: 992px) {
      footer .visit .side {
        width: 50%; }
        footer .visit .side:nth-of-type(2) {
          padding-left: 80px;
          box-sizing: border-box; } }
    footer .visit .side__title {
      font-size: 30px;
      color: #424242;
      text-transform: uppercase;
      width: fit-content;
      padding-bottom: 10px;
      border-bottom: 1px solid #DF1414;
      margin-bottom: 80px; }
    footer .visit .side__facebook {
      width: 80%;
      height: auto; }
    footer .visit .side__text {
      font-size: 23px;
      color: #1883E0;
      margin-top: 30px;
      width: 100%; }
    footer .visit .side__fblink {
      display: block;
      text-align: left;
      margin-top: 25px; }
    footer .visit .side__movies {
      position: relative;
      max-width: 450px;
      width: 100%; }
      footer .visit .side__movies .list {
        position: relative;
        max-width: 450px;
        width: 100%;
        height: 300px;
        border: 4px solid #d12230;
        overflow: hidden; }
        footer .visit .side__movies .list .movie {
          position: absolute;
          top: 0;
          left: 100%;
          width: 100%;
          height: 100%;
          transition: .5s; }
          footer .visit .side__movies .list .movie.active {
            z-index: 1;
            left: 0; }
          footer .visit .side__movies .list .movie iframe {
            width: inherit;
            height: inherit; }
      footer .visit .side__movies .controls {
        font-size: 0;
        text-align: center;
        margin-top: -5px; }
        footer .visit .side__movies .controls .movie-control {
          display: inline-block;
          width: 40px;
          padding: 10px 0;
          margin: 1px;
          font-size: 14px;
          color: white;
          background-color: #d12230;
          cursor: pointer; }
          footer .visit .side__movies .controls .movie-control:hover {
            background-color: black; }
          footer .visit .side__movies .controls .movie-control.active {
            background-color: black; }

footer .wrapper-background {
  padding-top: 80px;
  padding-bottom: 80px;
  background: #F7F7F7; }
  footer .wrapper-background .contact {
    position: relative; }
    @media screen and (min-width: 992px) {
      footer .wrapper-background .contact:before {
        position: absolute;
        content: '';
        background: url("../images/contact-footer-icon.png");
        width: 263px;
        height: 222px;
        bottom: -79px;
        left: 178px; } }
    footer .wrapper-background .contact__title {
      font-size: 30px;
      color: #424242;
      text-transform: uppercase;
      width: fit-content;
      padding-bottom: 10px;
      border-bottom: 1px solid #DF1414;
      margin-bottom: 80px; }
    footer .wrapper-background .contact__icons {
      position: fixed;
      bottom: 40px;
      right: 20px;
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
      z-index: 999; }
      footer .wrapper-background .contact__icons a {
        margin-right: 20px;
        cursor: pointer; }
    footer .wrapper-background .contact .contact__box {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      z-index: 1; }
      footer .wrapper-background .contact .contact__box .box {
        width: 100%;
        line-height: 1.9;
        margin-bottom: 30px; }
        @media screen and (min-width: 992px) {
          footer .wrapper-background .contact .contact__box .box {
            width: 33.3333%; } }
        footer .wrapper-background .contact .contact__box .box__title {
          font-size: 16px;
          font-weight: bold; }
        footer .wrapper-background .contact .contact__box .box__text {
          font-size: 16px; }
          footer .wrapper-background .contact .contact__box .box__text a {
            color: inherit; }
            footer .wrapper-background .contact .contact__box .box__text a:hover {
              text-decoration: underline; }

footer .menu-border {
  width: 100%;
  padding-top: 10px;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 15px;
  display: none; }
  @media screen and (min-width: 992px) {
    footer .menu-border {
      display: block; } }
  footer .menu-border .nav__menu--footer {
    justify-content: center;
    width: 100%;
    margin-bottom: 0px; }
    footer .menu-border .nav__menu--footer .menu__item {
      padding-bottom: 30px; }
      footer .menu-border .nav__menu--footer .menu__item:hover {
        padding-bottom: 27px; }

footer .created-by {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 20px; }
  footer .created-by a {
    width: fit-content;
    font-size: 14px;
    color: #414141;
    text-decoration: none; }
    footer .created-by a span {
      color: #DF1414; }

footer .cookies-wrapper {
  display: none;
  position: fixed;
  z-index: 1001;
  width: 90%;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto; }
  footer .cookies-wrapper.active {
    display: block; }
  footer .cookies-wrapper .contact-box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 20px 30px;
    background-color: white;
    border: 1px solid #ef1920; }
    @media screen and (min-width: 992px) {
      footer .cookies-wrapper .contact-box {
        flex-wrap: nowrap;
        align-items: center; } }
    footer .cookies-wrapper .contact-box__title {
      font-size: 24px;
      color: #ef1920; }
      @media screen and (min-width: 992px) {
        footer .cookies-wrapper .contact-box__title {
          flex-shrink: 0;
          width: 190px; } }
    footer .cookies-wrapper .contact-box__content {
      padding: 20px 0;
      font-size: 13px;
      color: #000000; }
      @media screen and (min-width: 992px) {
        footer .cookies-wrapper .contact-box__content {
          padding: 0 30px;
          margin-left: 20px;
          border-left: 1px solid #ef1920; } }
    footer .cookies-wrapper .contact-box__close {
      padding: 15px 30px;
      color: white;
      background-color: #ef1920;
      border: none;
      cursor: pointer; }
      @media screen and (min-width: 992px) {
        footer .cookies-wrapper .contact-box__close {
          flex-shrink: 0;
          height: 45px; } }

footer .modal-overlay {
  display: none;
  position: fixed;
  z-index: 10000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.5;
  box-sizing: border-box; }
  footer .modal-overlay.active {
    display: block; }

footer .modal {
  display: none;
  position: fixed;
  z-index: 1000000;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  max-width: 1200px;
  width: 100%;
  height: 100%;
  padding: 15px;
  box-sizing: border-box; }
  footer .modal.active {
    display: flex;
    flex-direction: column; }
  footer .modal .modal-header {
    font-size: 20px;
    color: white;
    padding: 17px 20px;
    background-color: #ed1e2b; }
    footer .modal .modal-header .modal-close {
      padding: 0 30px;
      font-size: 26px;
      cursor: pointer;
      position: absolute;
      right: 0px;
      top: 25px; }
  footer .modal .modal-content {
    display: flex;
    flex-direction: column;
    padding: 17px 20px;
    height: auto;
    background-color: white;
    overflow-y: auto; }
    footer .modal .modal-content .modal-response {
      display: none;
      padding: 10px 20px;
      margin-bottom: 10px;
      color: white; }
      footer .modal .modal-content .modal-response.danger {
        display: block;
        background-color: #ea434e; }
      footer .modal .modal-content .modal-response.success {
        display: block;
        background-color: #52a554; }
    footer .modal .modal-content .modal-input-group {
      margin-bottom: 20px; }
      @media screen and (min-width: 576px) {
        footer .modal .modal-content .modal-input-group {
          flex-direction: initial;
          display: flex; } }
      footer .modal .modal-content .modal-input-group input[type="text"] {
        width: 100%;
        margin-bottom: 15px; }
        @media screen and (min-width: 576px) {
          footer .modal .modal-content .modal-input-group input[type="text"] {
            margin: 0 20px; } }
        footer .modal .modal-content .modal-input-group input[type="text"]:first-of-type {
          margin-left: 0; }
        footer .modal .modal-content .modal-input-group input[type="text"]:last-of-type {
          margin-right: 0; }
    footer .modal .modal-content input[type="text"],
    footer .modal .modal-content textarea {
      padding: 6px 12px;
      font-size: 14px;
      color: #555;
      background-color: transparent;
      border: 1px solid #cfcfcf;
      box-sizing: border-box; }
    footer .modal .modal-content textarea {
      width: 100%;
      min-height: 130px;
      margin-bottom: 20px; }
    footer .modal .modal-content label {
      margin-top: 5px;
      font-size: 12px;
      color: #424242; }
      footer .modal .modal-content label a {
        color: #ed1d2b; }
    footer .modal .modal-content .text {
      margin: 20px 0;
      font-size: 12px;
      color: #424242; }
    footer .modal .modal-content button {
      height: 40px;
      line-height: 5px;
      padding: 20px;
      max-width: 300px;
      width: 100%;
      font-size: 14px;
      color: white;
      background-color: #ed1d2b;
      border: none;
      cursor: pointer;
      box-sizing: border-box; }
  footer .modal.modal-phone {
    max-width: 400px; }
    footer .modal.modal-phone .modal-content button {
      margin: 20px 0;
      align-self: center; }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin: 0 auto; }
  @media screen and (min-width: 576px) {
    .container {
      width: 540px; } }
  @media screen and (min-width: 768px) {
    .container {
      width: 720px; } }
  @media screen and (min-width: 992px) {
    .container {
      width: 960px; } }
  @media screen and (min-width: 1200px) {
    .container {
      width: 1170px; } }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.gallery .title {
  font-size: 30px;
  color: #424242;
  width: fit-content;
  text-transform: uppercase;
  padding-bottom: 10px;
  border-bottom: 1px solid #DF1414;
  margin-bottom: 60px;
  line-height: 1.5;
  font-weight: normal; }

.gallery .gallery-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto; }
  @media screen and (min-width: 992px) {
    .gallery .gallery-section {
      width: 85%; } }
  @media screen and (min-width: 1200px) {
    .gallery .gallery-section {
      width: 100%;
      justify-content: flex-start; } }
  .gallery .gallery-section .gallery-item {
    width: 100%;
    height: 260px;
    position: relative;
    margin-bottom: 30px;
    display: flex;
    justify-content: center; }
    @media screen and (min-width: 768px) {
      .gallery .gallery-section .gallery-item {
        width: 44%; } }
    @media screen and (min-width: 992px) {
      .gallery .gallery-section .gallery-item {
        width: 50%; } }
    @media screen and (min-width: 1200px) {
      .gallery .gallery-section .gallery-item {
        width: 33.333%;
        padding-right: 20px;
        box-sizing: border-box; } }
    .gallery .gallery-section .gallery-item img {
      width: 360px;
      height: 260px;
      object-fit: cover; }
    .gallery .gallery-section .gallery-item:before {
      position: absolute;
      content: '';
      width: 360px;
      height: 260px;
      transition: 1s; }
    .gallery .gallery-section .gallery-item:after {
      position: absolute;
      content: '';
      width: 36px;
      height: 36px;
      transition: 1s;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      top: calc(50% - 18px);
      transition: 1s; }
    .gallery .gallery-section .gallery-item:hover:before {
      background: rgba(223, 20, 20, 0.8); }
    .gallery .gallery-section .gallery-item:hover:after {
      background: url("../images/gallery-loop.png"); }

/*.gallery {
    .title {
        font-size: 30px;
        color: $ui-09;
        width: fit-content;
        text-transform: uppercase;
        padding-bottom: 10px;
        border-bottom: 1px solid $ui-06;
        margin-bottom: 60px;
        line-height: 1.5;
        font-weight: normal;
    }

    .gallery-section {
        display: flex;
        flex-wrap: wrap;

        a {
            position: relative;
            margin: 0 10px;
            font-size: 0;

            &:hover {
                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(223, 20, 20, 0.5);
                    background-image: url(../images/gallery-loop.png);
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            img {
                width: 360px;
                height: 260px;
                object-fit: cover;
            }
        }
    }
}*/
.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin: 0 auto; }
  @media screen and (min-width: 576px) {
    .container {
      width: 540px; } }
  @media screen and (min-width: 768px) {
    .container {
      width: 720px; } }
  @media screen and (min-width: 992px) {
    .container {
      width: 960px; } }
  @media screen and (min-width: 1200px) {
    .container {
      width: 1170px; } }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.content-subpage__title {
  font-size: 30px;
  color: #424242;
  width: fit-content;
  text-transform: uppercase;
  padding-bottom: 10px;
  border-bottom: 1px solid #DF1414;
  margin-bottom: 60px;
  line-height: 1.5; }

.content-subpage__text {
  font-size: 16px;
  font-weight: regular;
  color: #424242; }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin: 0 auto; }
  @media screen and (min-width: 576px) {
    .container {
      width: 540px; } }
  @media screen and (min-width: 768px) {
    .container {
      width: 720px; } }
  @media screen and (min-width: 992px) {
    .container {
      width: 960px; } }
  @media screen and (min-width: 1200px) {
    .container {
      width: 1170px; } }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.content-about__title {
  font-size: 30px;
  color: #424242;
  width: fit-content;
  text-transform: uppercase;
  padding-bottom: 10px;
  border-bottom: 1px solid #DF1414;
  margin-bottom: 60px;
  line-height: 1.5; }

.content-about__text {
  font-size: 16px;
  font-weight: regular;
  color: #424242; }

.content-about--background {
  width: 100%;
  background: #F7F7F7;
  padding-top: 70px;
  padding-bottom: 60px;
  margin-top: 60px; }
  .content-about--background .content-about__box {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    @media screen and (min-width: 768px) {
      .content-about--background .content-about__box {
        justify-content: flex-start; } }
    .content-about--background .content-about__box .box {
      width: 293px;
      height: 293px; }
      @media screen and (min-width: 992px) {
        .content-about--background .content-about__box .box {
          border: 0.5px solid #B7B7B7 !important;
          width: 283px;
          height: 283px; } }
      .content-about--background .content-about__box .box:nth-of-type(9) {
        border-bottom: 1px solid #B7B7B7; }
      @media screen and (min-width: 768px) {
        .content-about--background .content-about__box .box--developer {
          width: 589px; } }
      @media screen and (min-width: 1200px) {
        .content-about--background .content-about__box .box--developer {
          width: 567px; } }
      @media screen and (min-width: 992px) {
        .content-about--background .content-about__box .box--developer {
          border: 0px !important; } }
      .content-about--background .content-about__box .box--history {
        border: 1px solid #B7B7B7;
        border-bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column; }
        .content-about--background .content-about__box .box--history div {
          width: 100%;
          color: #424242;
          font-size: 18px;
          text-align: center; }
          .content-about--background .content-about__box .box--history div:nth-of-type(1) {
            color: #DF1414;
            font-size: 60px;
            font-weight: bold;
            margin-bottom: 45px; }
          .content-about--background .content-about__box .box--history div:last-child {
            margin-bottom: 0px; }
      .content-about--background .content-about__box .box--img {
        display: flex;
        justify-content: center;
        align-items: center; }
        @media screen and (min-width: 1200px) {
          .content-about--background .content-about__box .box--img img {
            width: 283px;
            height: 283px;
            object-fit: scale-down; } }
      .content-about--background .content-about__box .box__title {
        font-size: 20px;
        color: #424242;
        width: fit-content;
        text-transform: uppercase;
        padding-bottom: 10px;
        border-bottom: 1px solid #DF1414;
        margin-bottom: 50px; }
        @media screen and (min-width: 768px) {
          .content-about--background .content-about__box .box__title {
            font-size: 30px; } }
      .content-about--background .content-about__box .box__text {
        font-size: 28px;
        color: #424242;
        width: fit-content;
        text-transform: uppercase;
        line-height: 1.5; }
        @media screen and (min-width: 768px) {
          .content-about--background .content-about__box .box__text {
            font-size: 48px; } }
        .content-about--background .content-about__box .box__text span {
          width: 100%;
          display: block;
          font-weight: bold; }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin: 0 auto; }
  @media screen and (min-width: 576px) {
    .container {
      width: 540px; } }
  @media screen and (min-width: 768px) {
    .container {
      width: 720px; } }
  @media screen and (min-width: 992px) {
    .container {
      width: 960px; } }
  @media screen and (min-width: 1200px) {
    .container {
      width: 1170px; } }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.portfolio__title {
  font-size: 30px;
  color: #424242;
  width: fit-content;
  text-transform: uppercase;
  padding-bottom: 10px;
  border-bottom: 1px solid #DF1414;
  margin-bottom: 60px; }

.portfolio__box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media screen and (min-width: 992px) {
    .portfolio__box {
      flex-wrap: wrap;
      flex-direction: unset;
      justify-content: space-between;
      width: 80%;
      margin: 0 auto; } }
  @media screen and (min-width: 1200px) {
    .portfolio__box {
      width: 100%; } }
  .portfolio__box .box {
    width: 360px;
    height: 550px;
    display: block;
    margin-bottom: 50px;
    text-decoration: none; }
    .portfolio__box .box__img {
      width: 360px;
      height: 263px;
      margin-bottom: 30px;
      position: relative; }
      .portfolio__box .box__img:before {
        position: absolute;
        content: '';
        width: 360px;
        height: 263px; }
      .portfolio__box .box__img:after {
        position: absolute;
        content: '';
        width: 36px;
        height: 36px;
        transition: 1s;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        top: calc(50% - 18px);
        transition: 1s; }
      .portfolio__box .box__img img {
        width: 360px;
        height: 263px; }
    .portfolio__box .box__title {
      color: #424242;
      font-size: 24px;
      padding-bottom: 5px;
      border-bottom: 2px solid #ef1920;
      width: 100%;
      margin-bottom: 30px; }
    .portfolio__box .box__text {
      font-size: 16px;
      color: #424242;
      line-height: 1.5; }
      .portfolio__box .box__text span {
        width: 100%;
        font-weight: bold;
        display: block; }
    .portfolio__box .box:hover .box__img:before {
      background: rgba(223, 20, 20, 0.8); }
    .portfolio__box .box:hover .box__img:after {
      background: url("../images/gallery-loop.png"); }

.portfolio__content img {
  float: left;
  margin: 0 25px 15px 0; }

.portfolio .clear {
  clear: both; }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin: 0 auto; }
  @media screen and (min-width: 576px) {
    .container {
      width: 540px; } }
  @media screen and (min-width: 768px) {
    .container {
      width: 720px; } }
  @media screen and (min-width: 992px) {
    .container {
      width: 960px; } }
  @media screen and (min-width: 1200px) {
    .container {
      width: 1170px; } }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.content-contact__title {
  font-size: 30px;
  color: #424242;
  width: fit-content;
  text-transform: uppercase;
  padding-bottom: 10px;
  border-bottom: 1px solid #DF1414;
  margin-bottom: 60px; }

.content-contact__line {
  width: 100%;
  height: 1px;
  margin: 10px 0 40px;
  background-color: #cfcfcf; }

.content-contact__boxes {
  display: flex;
  flex-wrap: wrap; }
  .content-contact__boxes .box {
    width: 100%;
    line-height: 1.9;
    margin-bottom: 30px; }
    @media screen and (min-width: 992px) {
      .content-contact__boxes .box {
        width: 33.3333%; } }
    .content-contact__boxes .box div {
      font-size: 16px;
      color: #424242;
      line-height: 1.4; }
      .content-contact__boxes .box div:first-of-type {
        font-weight: bold;
        margin: 0 0 10px; }

.content-contact__formbox {
  background-image: url(../images/contact-bg.png);
  background-repeat: no-repeat;
  background-position: right 150px top 100px; }
  .content-contact__formbox .form {
    display: flex;
    flex-direction: column;
    max-width: 570px; }
    .content-contact__formbox .form input[type=text], .content-contact__formbox .form textarea {
      padding: 16px 15px;
      margin: 10px 0;
      text-align: center;
      border: 1px solid #cfcfcf; }
    .content-contact__formbox .form textarea {
      min-height: 130px; }
    .content-contact__formbox .form input[type=submit] {
      padding: 12px 21px;
      font-size: 14px;
      color: #fdfdfd;
      max-width: 185px;
      align-self: flex-end;
      background-color: #df1414;
      border: none;
      cursor: pointer; }
    .content-contact__formbox .form__group {
      display: flex;
      justify-content: space-between; }
      .content-contact__formbox .form__group input[type=text] {
        width: 43%; }
    .content-contact__formbox .form label {
      font-size: 14px;
      margin: 5px 0 25px; }
      .content-contact__formbox .form label a {
        text-decoration: none;
        color: #df1414; }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin: 0 auto; }
  @media screen and (min-width: 576px) {
    .container {
      width: 540px; } }
  @media screen and (min-width: 768px) {
    .container {
      width: 720px; } }
  @media screen and (min-width: 992px) {
    .container {
      width: 960px; } }
  @media screen and (min-width: 1200px) {
    .container {
      width: 1170px; } }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.content-offer__title {
  font-size: 30px;
  color: #424242;
  width: fit-content;
  text-transform: uppercase;
  padding-bottom: 10px;
  border-bottom: 1px solid #DF1414;
  margin-bottom: 60px; }

.content-offer__text {
  font-size: 16px;
  font-weight: regular;
  color: #424242;
  line-height: 1.5; }

.content-offer__box {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media screen and (min-width: 992px) {
    .content-offer__box {
      flex-direction: unset;
      justify-content: space-between;
      margin: 20px 0; } }
  .content-offer__box .box {
    width: 360px;
    height: 300px;
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    background-position: center !important;
    background-size: cover !important;
    margin-top: 35px;
    text-decoration: none;
    position: relative; }
    @media screen and (min-width: 992px) {
      .content-offer__box .box {
        width: 290px;
        height: 403px; } }
    @media screen and (min-width: 1200px) {
      .content-offer__box .box {
        width: 360px;
        height: 473px; } }
    .content-offer__box .box__city {
      font-size: 18px;
      color: white;
      z-index: 1;
      position: absolute;
      bottom: 35px; }
      .content-offer__box .box__city span {
        width: 100%;
        display: block; }
        .content-offer__box .box__city span:first-child {
          text-transform: uppercase; }
    .content-offer__box .box:before {
      position: absolute;
      content: '';
      bottom: 0;
      left: 0;
      width: 100%;
      height: 90px;
      background: rgba(0, 0, 0, 0.8);
      transition: 1s; }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin: 0 auto; }
  @media screen and (min-width: 576px) {
    .container {
      width: 540px; } }
  @media screen and (min-width: 768px) {
    .container {
      width: 720px; } }
  @media screen and (min-width: 992px) {
    .container {
      width: 960px; } }
  @media screen and (min-width: 1200px) {
    .container {
      width: 1170px; } }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.container .content-localization__title {
  font-size: 30px;
  font-weight: normal;
  color: #424242;
  width: fit-content;
  text-transform: uppercase;
  padding-bottom: 10px;
  border-bottom: 1px solid #DF1414;
  margin-bottom: 60px;
  line-height: 1.5; }

.container .content-localization__text {
  font-size: 16px;
  color: #424242;
  margin-bottom: 60px; }
  .container .content-localization__text h2, .container .content-localization__text h3 {
    text-transform: uppercase;
    font-weight: normal;
    font-size: 20px;
    width: fit-content; }
  .container .content-localization__text .sprawdz-liste {
    background: #DF1414;
    color: white;
    width: 188px;
    text-transform: uppercase;
    font-size: 14px;
    border: 0px;
    display: block;
    text-decoration: none;
    padding: 10px 20px;
    margin: 10px auto 10px auto; }

.news-offer {
  width: 100%;
  text-decoration: none;
  margin-bottom: 60px;
  display: block; }
  .news-offer__title {
    font-size: 30px;
    color: #424242;
    width: fit-content;
    text-transform: uppercase;
    padding-bottom: 10px;
    border-bottom: 1px solid #DF1414;
    margin-bottom: 60px; }
  .news-offer__img {
    width: 100%;
    height: 230px;
    object-fit: cover; }
  .news-offer__text {
    font-size: 16px;
    font-weight: regular;
    color: #424242;
    line-height: 1.5;
    margin-top: 30px;
    margin-bottom: 30px; }
  .news-offer__more {
    background: #DF1414;
    color: white;
    width: 188px;
    text-transform: uppercase;
    font-size: 14px;
    border: 0px;
    text-decoration: none; }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin: 0 auto; }
  @media screen and (min-width: 576px) {
    .container {
      width: 540px; } }
  @media screen and (min-width: 768px) {
    .container {
      width: 720px; } }
  @media screen and (min-width: 992px) {
    .container {
      width: 960px; } }
  @media screen and (min-width: 1200px) {
    .container {
      width: 1170px; } }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.content-offer-list .find-apartment {
  font-size: 30px;
  color: #424242;
  width: fit-content;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  margin-top: 80px;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto; }
  .content-offer-list .find-apartment span {
    color: #DF1414; }
  .content-offer-list .find-apartment:before {
    position: absolute;
    content: '';
    width: 164px;
    height: 164px;
    background: url("../images/icon-loop.png");
    right: -90px;
    top: -40px;
    z-index: -1;
    display: none; }
    @media screen and (min-width: 576px) {
      .content-offer-list .find-apartment:before {
        display: block; } }

.content-offer-list .form-search {
  background: #000000;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  z-index: 1;
  position: relative; }
  @media screen and (min-width: 576px) {
    .content-offer-list .form-search {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; } }
  @media screen and (min-width: 768px) {
    .content-offer-list .form-search {
      align-items: center; } }
  .content-offer-list .form-search__field {
    margin-bottom: 15px; }
    .content-offer-list .form-search__field--select {
      max-width: 344px;
      width: 100%; }
      @media screen and (min-width: 576px) {
        .content-offer-list .form-search__field--select {
          margin-right: 15px; } }
      @media screen and (min-width: 1200px) {
        .content-offer-list .form-search__field--select {
          width: 283px; } }
    .content-offer-list .form-search__field--input {
      width: 170px;
      display: flex;
      flex-wrap: wrap; }
    .content-offer-list .form-search__field label {
      width: 100%;
      font-size: 14px;
      color: white;
      display: block;
      margin-bottom: 15px;
      text-transform: uppercase; }
    .content-offer-list .form-search__field select {
      width: 100%;
      font-size: 14px;
      color: white;
      height: 33px;
      border: 1px solid #575757;
      background: transparent; }
      .content-offer-list .form-search__field select option {
        color: #000000; }
    .content-offer-list .form-search__field input {
      width: 70px;
      font-size: 14px;
      color: white;
      height: 33px;
      border: 1px solid #575757;
      background: transparent;
      margin-right: 10px;
      padding-left: 15px;
      box-sizing: border-box; }
  .content-offer-list .form-search__submit {
    background: #DF1414;
    color: white;
    width: 174px;
    text-transform: uppercase;
    font-size: 14px;
    border: 0px; }
    @media screen and (min-width: 768px) {
      .content-offer-list .form-search__submit {
        margin-top: 15px; } }

.content-offer-list__title {
  font-size: 30px;
  color: #424242;
  width: fit-content;
  text-transform: uppercase;
  padding-bottom: 10px;
  border-bottom: 1px solid #DF1414;
  margin-bottom: 60px;
  margin-top: 60px; }

.content-offer-list__text {
  font-size: 16px;
  font-weight: regular;
  color: #424242;
  line-height: 1.5;
  margin-top: 30px;
  margin-bottom: 30px; }

.content-offer-list__icons {
  display: flex;
  flex-wrap: wrap; }
  .content-offer-list__icons .icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; }
    @media screen and (min-width: 576px) {
      .content-offer-list__icons .icons {
        width: 50%; } }
    @media screen and (min-width: 768px) {
      .content-offer-list__icons .icons {
        width: 33.3%; } }
    @media screen and (min-width: 992px) {
      .content-offer-list__icons .icons {
        width: 16.6%; } }
    .content-offer-list__icons .icons__img {
      width: 100px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .content-offer-list__icons .icons__text {
      width: 125px;
      text-align: center; }

.content-offer-list table {
  width: 100%; }
  .content-offer-list table th {
    font-size: 14px;
    font-weight: bold;
    background: #F0F0F0;
    cursor: pointer; }
  .content-offer-list table tr {
    width: 100%;
    height: 60px;
    font-size: 14px;
    line-height: 60px;
    color: #424242;
    border-bottom: 1px solid #F0F0F0; }
    .content-offer-list table tr td {
      text-align: center;
      line-height: 60px;
      height: 60px; }

.content-offer-list__video {
  width: 100%;
  height: 500px;
  border: 4px solid #d12230; }

.content-offer-list__gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto; }
  @media screen and (min-width: 992px) {
    .content-offer-list__gallery {
      width: 85%; } }
  @media screen and (min-width: 1200px) {
    .content-offer-list__gallery {
      width: 100%;
      justify-content: flex-start; } }
  .content-offer-list__gallery .gallery {
    width: 100%;
    height: 260px;
    position: relative;
    margin-bottom: 30px;
    display: flex;
    justify-content: center; }
    @media screen and (min-width: 768px) {
      .content-offer-list__gallery .gallery {
        width: 44%; } }
    @media screen and (min-width: 992px) {
      .content-offer-list__gallery .gallery {
        width: 50%; } }
    @media screen and (min-width: 1200px) {
      .content-offer-list__gallery .gallery {
        width: 33.333%;
        padding-right: 20px;
        box-sizing: border-box; } }
    .content-offer-list__gallery .gallery img {
      width: 360px;
      height: 260px; }
    .content-offer-list__gallery .gallery:before {
      position: absolute;
      content: '';
      width: 360px;
      height: 260px;
      transition: 1s; }
    .content-offer-list__gallery .gallery:after {
      position: absolute;
      content: '';
      width: 36px;
      height: 36px;
      transition: 1s;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      top: calc(50% - 18px);
      transition: 1s; }
    .content-offer-list__gallery .gallery:hover:before {
      background: rgba(223, 20, 20, 0.8); }
    .content-offer-list__gallery .gallery:hover:after {
      background: url("../images/gallery-loop.png"); }

.content-offer-list__map {
  display: flex;
  flex-direction: column; }
  @media screen and (min-width: 992px) {
    .content-offer-list__map {
      flex-direction: row; } }
  .content-offer-list__map--left {
    width: 100%; }
    .content-offer-list__map--left .google-maps {
      width: 100%;
      height: 400px;
      background: #d0d0d0;
      color: white; }
  .content-offer-list__map--right {
    width: 100%;
    margin-top: 20px; }
    @media screen and (min-width: 992px) {
      .content-offer-list__map--right {
        max-width: 25%;
        padding-left: 40px;
        margin-top: 0; } }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.container {
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  margin: 0 auto; }
  @media screen and (min-width: 576px) {
    .container {
      width: 540px; } }
  @media screen and (min-width: 768px) {
    .container {
      width: 720px; } }
  @media screen and (min-width: 992px) {
    .container {
      width: 960px; } }
  @media screen and (min-width: 1200px) {
    .container {
      width: 1170px; } }

.subpage {
  margin-bottom: 60px; }
  .subpage--about {
    margin-bottom: 0px; }

a {
  text-decoration: none;
  color: #ef1920; }

.overflow-table {
  overflow-x: auto;
  width: 100%; }

h1 {
  font-weight: 300; }

.menu-page {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0px;
  width: 100%;
  margin-top: 50px; }
  .menu-page li {
    background-color: #373737;
    width: 32.3%;
    height: 53px;
    margin-right: 1px;
    margin-top: 1px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: all 0.5s;
    padding-top: 5px;
    padding-bottom: 5px; }
    .menu-page li:hover {
      background-color: #df1414; }
    .menu-page li.disabled {
      background-color: #737373;
      cursor: default; }
    .menu-page li div {
      display: none; }
    @media screen and (min-width: 768px) {
      .menu-page li {
        width: 45%; } }
    @media screen and (min-width: 992px) {
      .menu-page li {
        width: 30%; }
        .menu-page li div {
          display: block; }
        .menu-page li img {
          display: none; } }
    @media screen and (min-width: 1200px) {
      .menu-page li {
        width: 189px; } }
    .menu-page li:last-child {
      margin-right: 0px; }
    .menu-page li a {
      color: #fdfdfd;
      font-size: 18px;
      font-weight: 400;
      text-align: center;
      height: 53px;
      line-height: 53px;
      text-transform: uppercase;
      display: block;
      width: 100%; }

.content-offer-list .find-apartment {
  font-size: 30px;
  color: #424242;
  width: fit-content;
  text-align: center;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
  margin-top: 80px;
  margin-bottom: 80px;
  margin-left: auto;
  margin-right: auto; }
  .content-offer-list .find-apartment span {
    color: #DF1414; }
  .content-offer-list .find-apartment:before {
    position: absolute;
    content: '';
    width: 164px;
    height: 164px;
    background: url("../images/icon-loop.png");
    right: -90px;
    top: -40px;
    z-index: -1;
    display: none; }
    @media screen and (min-width: 576px) {
      .content-offer-list .find-apartment:before {
        display: block; } }

.content-offer-list .form-search {
  background: #000000;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
  z-index: 1;
  position: relative; }
  .content-offer-list .form-search--custom {
    margin-top: -80px; }
  @media screen and (min-width: 576px) {
    .content-offer-list .form-search {
      display: flex;
      flex-wrap: wrap;
      justify-content: center; } }
  @media screen and (min-width: 768px) {
    .content-offer-list .form-search {
      align-items: center; } }
  .content-offer-list .form-search__field {
    margin-bottom: 15px; }
    .content-offer-list .form-search__field--select {
      max-width: 344px;
      width: 100%; }
      @media screen and (min-width: 576px) {
        .content-offer-list .form-search__field--select {
          margin-right: 15px; } }
      @media screen and (min-width: 1200px) {
        .content-offer-list .form-search__field--select {
          width: 283px; } }
    .content-offer-list .form-search__field--input {
      width: 170px;
      display: flex;
      flex-wrap: wrap; }
    .content-offer-list .form-search__field label {
      width: 100%;
      font-size: 14px;
      color: white;
      display: block;
      margin-bottom: 15px;
      text-transform: uppercase; }
    .content-offer-list .form-search__field select {
      width: 100%;
      font-size: 14px;
      color: white;
      height: 33px;
      border: 1px solid #575757;
      background: transparent; }
      .content-offer-list .form-search__field select option {
        color: #000000; }
    .content-offer-list .form-search__field input {
      width: 70px;
      font-size: 14px;
      color: white;
      height: 33px;
      border: 1px solid #575757;
      background: transparent;
      margin-right: 10px;
      padding-left: 15px;
      box-sizing: border-box; }
      .content-offer-list .form-search__field input::placeholder {
        color: white; }
  .content-offer-list .form-search__submit {
    background: #DF1414;
    color: white;
    width: 174px;
    text-transform: uppercase;
    font-size: 14px;
    border: 0px; }
    @media screen and (min-width: 768px) {
      .content-offer-list .form-search__submit {
        margin-top: 15px; } }

.content-offer-list__title {
  font-size: 30px;
  color: #424242;
  width: fit-content;
  text-transform: uppercase;
  padding-bottom: 10px;
  border-bottom: 1px solid #DF1414;
  margin-bottom: 60px;
  margin-top: 60px; }

.content-offer-list__text {
  font-size: 16px;
  font-weight: regular;
  color: #424242;
  line-height: 1.5;
  margin-top: 30px;
  margin-bottom: 30px; }

.content-offer-list__icons {
  display: flex;
  flex-wrap: wrap; }
  .content-offer-list__icons .icons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; }
    @media screen and (min-width: 576px) {
      .content-offer-list__icons .icons {
        width: 50%; } }
    @media screen and (min-width: 768px) {
      .content-offer-list__icons .icons {
        width: 33.3%; } }
    @media screen and (min-width: 992px) {
      .content-offer-list__icons .icons {
        width: 16.6%; } }
    .content-offer-list__icons .icons__img {
      width: 100px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center; }
    .content-offer-list__icons .icons__text {
      width: 125px;
      text-align: center; }

.content-offer-list .apartments-header {
  display: flex;
  flex-direction: column;
  margin: 60px 0; }
  @media screen and (min-width: 992px) {
    .content-offer-list .apartments-header {
      flex-direction: row;
      justify-content: space-between; } }
  .content-offer-list .apartments-header .content-offer-list__title {
    margin: 0; }
  .content-offer-list .apartments-header .apartments-stats {
    display: flex;
    flex-direction: column;
    padding: 25px 10px;
    margin-top: 20px;
    border: 1px solid #d7d7d7; }
    @media screen and (min-width: 992px) {
      .content-offer-list .apartments-header .apartments-stats {
        flex-direction: row;
        margin-top: 0; } }
    .content-offer-list .apartments-header .apartments-stats__item {
      font-size: 18px;
      padding: 3px 0; }
      @media screen and (min-width: 992px) {
        .content-offer-list .apartments-header .apartments-stats__item {
          padding: 0 10px; } }
      .content-offer-list .apartments-header .apartments-stats__item.sold {
        color: #ef1920; }
      .content-offer-list .apartments-header .apartments-stats__item.rez {
        color: #f4862e; }
      .content-offer-list .apartments-header .apartments-stats__item.free {
        color: #2cab00; }

.content-offer-list table {
  width: 100%; }
  .content-offer-list table th {
    font-size: 14px;
    font-weight: bold;
    background: #F0F0F0;
    cursor: pointer; }
  .content-offer-list table tr {
    width: 100%;
    height: 60px;
    font-size: 14px;
    line-height: 60px;
    color: #424242;
    border-bottom: 1px solid #F0F0F0; }
    .content-offer-list table tr.rez {
      color: #f67c3f; }
    .content-offer-list table tr td {
      text-align: center;
      line-height: 60px;
      height: 60px; }

.content-offer-list__video {
  width: 100%;
  height: 500px;
  border: 4px solid #d12230; }

.content-offer-list__gallery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 auto; }
  @media screen and (min-width: 992px) {
    .content-offer-list__gallery {
      width: 85%; } }
  @media screen and (min-width: 1200px) {
    .content-offer-list__gallery {
      width: 100%;
      justify-content: flex-start; } }
  .content-offer-list__gallery .gallery {
    width: 100%;
    height: 260px;
    position: relative;
    margin-bottom: 30px;
    display: flex;
    justify-content: center; }
    @media screen and (min-width: 768px) {
      .content-offer-list__gallery .gallery {
        width: 44%; } }
    @media screen and (min-width: 992px) {
      .content-offer-list__gallery .gallery {
        width: 50%; } }
    @media screen and (min-width: 1200px) {
      .content-offer-list__gallery .gallery {
        width: 33.333%;
        padding-right: 20px;
        box-sizing: border-box; } }
    .content-offer-list__gallery .gallery img {
      width: 360px;
      height: 260px;
      object-fit: cover; }
    .content-offer-list__gallery .gallery:before {
      position: absolute;
      content: '';
      width: 360px;
      height: 260px;
      transition: 1s; }
    .content-offer-list__gallery .gallery:after {
      position: absolute;
      content: '';
      width: 36px;
      height: 36px;
      transition: 1s;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
      top: calc(50% - 18px);
      transition: 1s; }
    .content-offer-list__gallery .gallery:hover:before {
      background: rgba(223, 20, 20, 0.8); }
    .content-offer-list__gallery .gallery:hover:after {
      background: url("../images/gallery-loop.png"); }
