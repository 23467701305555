@import '../breakpoints.scss';
@import '../variables.scss';

.container {
    .content-localization {
        &__title {
            font-size: 30px;
            font-weight: normal;
            color: $ui-09;
            width: fit-content;
            text-transform: uppercase;
            padding-bottom: 10px;
            border-bottom: 1px solid $ui-06;
            margin-bottom: 60px;
            line-height: 1.5;
        }
        &__text {
            font-size: 16px;
            color: $ui-09;
            margin-bottom: 60px;

            h2, h3 {
                text-transform: uppercase;
                font-weight: normal;
                font-size: 20px;
                width: fit-content;
            }

            .sprawdz-liste {
                background: #DF1414;
                color: white;
                width: 188px;
                text-transform: uppercase;
                font-size: 14px;
                border: 0px;
                display: block;
                text-decoration: none;
                padding: 10px 20px;
                margin: 10px auto 10px auto;
            }
        }
    }
}
.news-offer {
    width: 100%;
    text-decoration: none;
    margin-bottom: 60px;
    display: block;

    &__title {
        font-size: 30px;
        color: $ui-09;
        width: fit-content;
        text-transform: uppercase;
        padding-bottom: 10px;
        border-bottom: 1px solid $ui-06;
        margin-bottom: 60px;
    }

    &__img {
        width: 100%;
        height: 230px;
        object-fit: cover;
    }

    &__text {
        font-size: 16px;
        font-weight: regular;
        color: $ui-09;
        line-height: 1.5;
        margin-top: 30px;
        margin-bottom: 30px;
    }

    &__more {
        background: $ui-06;
        color: $ui-00;
        width: 188px;
        text-transform: uppercase;
        font-size: 14px;
        border: 0px;
        text-decoration: none;
    }
}