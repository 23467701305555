@import '../breakpoints.scss';
@import '../variables.scss';


.main {
    .projects {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint($lg) {
            flex-direction: unset;
            justify-content: space-between;
            margin-top: -152px;
        }

        .box {
            width: 360px;
            height: 300px;
            padding-left: 15px;
            padding-right: 15px;
            box-sizing: border-box;
            background-position: center !important;
            background-size: cover !important;
            margin-top: 35px;
            text-decoration: none;
            position: relative;

            @include breakpoint($lg) {
                width: 290px;
                height: 403px;
            }

            @include breakpoint($xl) {
                width: 360px;
                height: 473px;
            }

            @include breakpoint($lg) {
                &:hover {

                    .box__text {
                        opacity: 1;
                    }

                    &:before {
                        height: 100%;
                    }
                }
            }

            &__text {
                width: 100%;
                font-size: 16px;
                font-weight: regular;
                color: $ui-00;
                padding-top: 60px;
                z-index: 1;
                position: relative;
                opacity: 0;
                transition: 1.8s;

                span {
                    font-size: 18px;
                    padding-top: 20px;
                    display: block;
                }
            }

            &__city {
                font-size: 17px;
                color: $ui-00;
                z-index: 1;
                position: absolute;
                bottom: 25px;

                span {
                    width: 100%;
                    display: block;

                    &:first-child {
                        text-transform: uppercase;
                    }
                }
            }

            &:before {
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                width: 100%;
                height: 90px;
                background: rgba($ui-03, 0.8);
                transition: 0.5s;
            }
        }
    }

    .find-apartment {
        font-size: 30px;
        color: $ui-09;
        width: fit-content;
        text-align: center;
        text-transform: uppercase;
        position: relative;
        z-index: 1;
        margin-top: 60px;
        margin-bottom: 70px;
        margin-left: auto;
        margin-right: auto;

        span {
            color: $ui-06;
        }

        &:before {
            position: absolute;
            content: '';
            width: 164px;
            height: 164px;
            background: url('../images/icon-loop.png');
            right: -90px;
            top: -40px;
            z-index: -1;
            display: none;

            @include breakpoint($sm) {
                display: block;
            }
        }
    }

    .form-search {
        background: $ui-03;
        width: 100%;
        padding-top: 30px;
        padding-bottom: 30px;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
        z-index: 1;
        position: relative;

        @include breakpoint($sm) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        @include breakpoint($md) {
            align-items: center;
        }

        &__field {
            margin-bottom: 15px;

            &--select {
                max-width: 344px;
                width: 100%;

                @include breakpoint($sm) {
                    margin-right: 15px;
                }

                @include breakpoint($xl) {
                    width: 283px;
                }
            }

            &--input {
                width: 170px;
                display: flex;
                flex-wrap: wrap;
            }

            label {
                width: 100%;
                font-size: 14px;
                color: $ui-00;
                display: block;
                margin-bottom: 15px;
                text-transform: uppercase;
            }

            select {
                width: 100%;
                font-size: 14px;
                color: $ui-00;
                height: 33px;
                border: 1px solid $ui-07;
                background: transparent;

                option {
                    color: $ui-03;
                }
            }

            input {
                width: 70px;
                font-size: 14px;
                color: $ui-00;
                height: 33px;
                border: 1px solid $ui-07;
                background: transparent;
                margin-right: 10px;
                padding-left: 15px;
                box-sizing: border-box;

                &::placeholder {
                    color: $ui-00;
                }
            }

        }

        &__submit {
            background: $ui-06;
            color: $ui-00;
            width: 174px;
            text-transform: uppercase;
            font-size: 14px;
            border: 0px;

            @include breakpoint($md) {
                margin-top: 15px;
            }
        }
    }

    .wrapper-background {
        background: $ui-08;
        padding-top: 135px;
        padding-bottom: 80px;

        @include breakpoint ($xl) {
            margin-top: -65px;
        }

        .about {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            @include breakpoint($md) {
                justify-content: flex-start;
            }

            .box {
                width: 293px;
                height: 293px;

                @include breakpoint($lg) {
                    border: 0.5px solid #B7B7B7 !important;
                    width: 283px;
                    height: 283px;
                }

                &:nth-of-type(9) {
                    border-bottom: 1px solid #B7B7B7;
                }


                &--developer {
                    @include breakpoint($md) {
                        width: 293px * 2 + 3px;
                    }

                    @include breakpoint($lg) {
                        border: 0px !important;
                        width: 293px * 2 - 19px;
                    }

                    @include breakpoint($xl) {
                        width: 293px * 2 - 19px;
                    }

                }



                &--history {
                    border: 1px solid $ui-10;
                    border-bottom: 0px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    div {
                        width: 100%;
                        color: $ui-09;
                        font-size: 18px;
                        text-align: center;

                        &:nth-of-type(1) {
                            color: $ui-06;
                            font-size: 60px;
                            font-weight: bold;
                            margin-bottom: 25px;
                        }

                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }

                    &:nth-child(2) {
                        background: red;
                    }
                }



                &--img {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    img {
                        @include breakpoint($lg) {
                            width: 283px;
                            height: 283px;
                            object-fit: scale-down;
                        }

                        @include breakpoint($xl) {
                            width: 283px;
                            height: 283px;
                            object-fit: scale-down;
                        }
                    }
                }

                &__title {
                    font-size: 20px;
                    color: $ui-09;
                    width: fit-content;
                    text-transform: uppercase;
                    padding-bottom: 10px;
                    border-bottom: 1px solid $ui-06;
                    margin-bottom: 50px;

                    @include breakpoint($md) {
                        font-size: 30px;
                    }
                }

                &__text {
                    font-size: 28px;
                    color: $ui-09;
                    width: fit-content;
                    text-transform: uppercase;
                    line-height: 1.5;

                    @include breakpoint($md) {
                        font-size: 48px;
                    }

                    span {
                        width: 100%;
                        display: block;
                        font-weight: bold;
                    }
                }

            }
        }
    }

    .wrapper-background-red {
        background: linear-gradient(180deg, $ui-06 65%, #fff 35%);

        @include breakpoint($sm) {
            background: linear-gradient(180deg, $ui-06 50%, #fff 50%);
        }

        @include breakpoint($xl) {
            background: linear-gradient(90deg, $ui-06 50%, #fff 50%);
            padding-top: 60px;
            padding-bottom: 60px;

        }
    }

    .about-us {
        @include breakpoint($xl) {
            display: flex;

        }

        .icons {
            width: 100%;
            padding-top: 60px;
            padding-bottom: 60px;

            @include breakpoint($xl) {
                width: 50%;
                padding-top: 0px;
                padding-bottom: 0px;

            }

            &__title {
                font-size: 30px;
                color: $ui-00;
                width: fit-content;
                text-transform: uppercase;
                padding-bottom: 10px;
                border-bottom: 2px solid $ui-00;
                margin-bottom: 60px;
            }

            .icons__box {
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                @include breakpoint($xl) {
                    width: 100%;
                }

                .box {
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin-bottom: 40px;



                    &:last-child {
                        margin-bottom: 0px;
                    }

                    @include breakpoint($sm) {
                        width: 33.3%;

                        &:nth-of-type(4),
                        &:nth-of-type(5) {
                            margin: 0px;
                        }

                    }

                    &__img {
                        width: 75px;
                        height: 75px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 20px;
                    }

                    &__text {
                        font-size: 18px;
                        color: $ui-00;
                        width: 140px;
                        text-align: center;
                    }
                }
            }
        }

        .developer {
            width: 100%;
            padding-top: 60px;
            padding-bottom: 60px;

            @include breakpoint($xl) {
                padding-top: 0px;
                padding-bottom: 0px;
                padding-left: 100px;
                box-sizing: border-box;
                width: 50%;

            }

            &__title {
                font-size: 30px;
                color: $ui-09;
                width: fit-content;
                text-transform: uppercase;
                padding-bottom: 10px;
                margin-bottom: 60px;
            }

            &__text {
                font-size: 16px;
                color: $ui-09;
                width: 100%;
                max-width: 456px;
                text-align: left;
            }

            &__more {
                background: $ui-06;
                color: $ui-00;
                width: 188px;
                text-transform: uppercase;
                font-size: 14px;
                border: 0px;
                text-decoration: none;
                margin-top: 40px;
            }
        }
    }
}