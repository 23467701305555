$screen-sm-min: 576px;
// Small tablets and large smartphones (landscape view)
$screen-md-min: 768px;

// Small tablets (portrait view)
$screen-lg-min: 992px;

// Tablets and desktops
$screen-xl-min: 1200px;


// Colors
$ui-00: white;
$ui-01: #ef1920;
$ui-02: #939393;
$ui-03: #000000;
$ui-04: #414141;
$ui-05: #707070;
$ui-06: #DF1414;
$ui-07: #575757;
$ui-08:#F7F7F7;
$ui-09:#424242;
$ui-10: #B7B7B7;
$ui-11: #1883E0;
$ui-12:#F0F0F0;
$ui-13:#e7e7e7;

.subpage {
    margin-bottom: 60px;

    &--about {
        margin-bottom: 0px;
    }
}

a {
    text-decoration: none;
    color: $ui-01;
}

.overflow-table {
    overflow-x: auto;
    width: 100%;
}

h1 {
    font-weight: 300;
}