@import '../breakpoints.scss';
@import '../variables.scss';

.portfolio {
    &__title {
        font-size: 30px;
        color: $ui-09;
        width: fit-content;
        text-transform: uppercase;
        padding-bottom: 10px;
        border-bottom: 1px solid $ui-06;
        margin-bottom: 60px;
    }

    &__box {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include breakpoint($lg) {
            flex-wrap: wrap;
            flex-direction: unset;
            justify-content: space-between;
            width: 80%;
            margin: 0 auto;
        }

        @include breakpoint($xl) {
            width: 100%;
        }

        .box {
            width: 360px;
            height: 550px;
            display: block;
            margin-bottom: 50px;
            text-decoration: none;

            &__img {
                width: 360px;
                height: 263px;
                margin-bottom: 30px;
                position: relative;

                &:before {
                    position: absolute;
                    content: '';
                    width: 360px;
                    height: 263px;
                }

                &:after {
                    position: absolute;
                    content: '';
                    width: 36px;
                    height: 36px;
                    transition: 1s;
                    left: 0;
                    right: 0;
                    margin-left: auto;
                    margin-right: auto;
                    top: calc(50% - 18px);
                    transition: 1s;

                }

                img {
                    width: 360px;
                    height: 263px;
                }
            }

            &__title {
                color: $ui-09;
                font-size: 24px;
                padding-bottom: 5px;
                border-bottom: 2px solid $ui-01;
                width: 100%;
                margin-bottom: 30px;
            }

            &__text {
                font-size: 16px;
                color: $ui-09;
                line-height: 1.5;

                span {
                    width: 100%;
                    font-weight: bold;
                    display: block;
                }
            }

            &:hover {
                .box__img {
                    &:before {
                        background: rgba($ui-06, 0.8);
                    }

                    &:after {
                        background: url('../images/gallery-loop.png')
                    }
                }
            }
        }
    }

    &__content {
        img {
            float: left;
            margin: 0 25px 15px 0;
        }
    }

    .clear {
        clear: both;
    }
}