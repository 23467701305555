@import '../breakpoints.scss';
@import '../variables.scss';

.rwd-flags {
	margin-left: 10px;
	display: flex;

	@include breakpoint($lg) {
		display: none;
	}

	a {
		width: 25px;
		height: 25px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 10px;
	}

}

.nav {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	position: relative;
	margin-top: 20px;

	@include breakpoint($lg) {
		margin-top: -70px;
		justify-content: space-between;
	}

	&__hamburger {
		width: 50px;
		background: transparent;
		border: 0px;
		padding-bottom: 15px;
		z-index: 3;
		transition: 1s;
		position: absolute;
		top: 15px;
		outline: none;
		right: 20px;

		@include breakpoint ($lg) {
			display: none;
		}

		div {
			width: 100%;
			height: 5px;
			margin-bottom: 6px;
			background: $ui-01;
			transition: 1s;

			&:last-child {
				margin-bottom: 0px;
			}
		}

		&.close {
			top: 60px;
			z-index: 100000;
			position: fixed;
			right: 20px;

			div {
				transform: rotate(-45deg);

				&:nth-of-type(2) {
					transform: rotate(45deg);
					margin-top: -10px;
				}

				&:nth-of-type(3) {
					opacity: 0;
					transform: rotate(0deg);
				}
			}
		}
	}

	&__menu {
		position: fixed;
		background: white;
		width: 300px;
		height: 100vh;
		right: 0;
		top: -15px;
		text-indent: 30px;
		list-style: none;
		padding-top: 50px;
		box-sizing: border-box;
		transform: translate(300px);
		transition: 1s;
		z-index: 9999;
		overflow: auto;

		&.show {
			transform: translate(0px);
		}

		@include breakpoint($lg) {
			position: static;
			transform: translate(0px);
			opacity: 1;
			display: flex;
			background: transparent;
			width: max-content;
			height: auto;
			text-indent: 0px;
			padding-top: 0px;
			overflow: inherit;
		}

		.menu {
			width: 100%;

			@include breakpoint($lg) {
				padding-right: 15px;
				width: fit-content;
				position: relative;
				transition: all 0.3s;

				&:last-child {
					padding-right: 0px;
				}
			}

			&__item {
				padding-top: 20px;
				font-size: 16px;
				font-weight: 400;
				color: $ui-04;
				text-transform: uppercase;
				text-decoration: none;
				width: 100%;
				display: block;
				padding-bottom: 10px;
				box-sizing: border-box;

				&--active {
					color: $ui-01;
					border-bottom: 3px solid $ui-01;
					padding-bottom: 7px;
				}

				&:hover {
					color: $ui-01;
					border-bottom: 3px solid $ui-01;
					padding-bottom: 7px;
				}

				@include breakpoint($lg) {
					width: fit-content;
				}
			}

			.child {
				background: white;
				list-style: none;
				margin-top: 20px;
				margin-bottom: 20px;

				transition: all 0.3s;

				@include breakpoint($lg) {
					position: absolute;
					min-width: 300px;
					text-indent: 5px;
					padding-bottom: 20px;
					display: none;
					margin-top: 0px;
					padding-top: 30px;

				}

				li {
					margin-bottom: 15px;

					a {
						color: $ui-04;
						padding-top: 20px;
						padding-bottom: 20px;
						width: 100%;
						display: block;

						&:hover {
							color: $ui-01;
						}
					}
				}
			}

			&:hover .child {
				@include breakpoint($lg) {
					display: block;

				}
			}
		}
	}
}