@import '../breakpoints.scss';
@import '../variables.scss';

footer {
    .google-maps {
        width: 100%;
        height: 629px;
    }

    .visit {
        padding-top: 40px;

        @include breakpoint($lg) {
            display: flex;
        }

        .side {
            margin-bottom: 40px;
            width: 100%;

            @include breakpoint($lg) {
                width: 50%;

                &:nth-of-type(2) {
                    padding-left: 80px;
                    box-sizing: border-box;
                }
            }

            &__title {
                font-size: 30px;
                color: $ui-09;
                text-transform: uppercase;
                width: fit-content;
                padding-bottom: 10px;
                border-bottom: 1px solid $ui-06;
                margin-bottom: 80px;
            }

            &__facebook {
                width: 80%;
                height: auto;
            }

            &__text {
                font-size: 23px;
                color: $ui-11;
                margin-top: 30px;
                width: 100%;
            }

            &__fblink {
                display: block;
                text-align: left;
                margin-top: 25px;
            }

            &__movies {
                position: relative;
                max-width: 450px;
                width: 100%;

                .list {
                    position: relative;
                    max-width: 450px;
                    width: 100%;
                    height: 300px;
                    border: 4px solid #d12230;
                    overflow: hidden;

                    .movie {
                        position: absolute;
                        top: 0;
                        left: 100%;
                        width: 100%;
                        height: 100%;
                        transition: .5s;

                        &.active {
                            z-index: 1;
                            left: 0;
                        }

                        iframe {
                            width: inherit;
                            height: inherit;
                        }
                    }
                }

                .controls {
                    font-size: 0;
                    text-align: center;
                    margin-top: -5px;

                    .movie-control {
                        display: inline-block;
                        width: 40px;
                        padding: 10px 0;
                        margin: 1px;
                        font-size: 14px;
                        color: white;
                        background-color: #d12230;
                        cursor: pointer;

                        &:hover {
                            background-color: black;
                        }

                        &.active {
                            background-color: black;
                        }
                    }
                }
            }
        }
    }

    .wrapper-background {
        padding-top: 80px;
        padding-bottom: 80px;
        background: $ui-08;

        .contact {
            position: relative;

            @include breakpoint($lg) {
                &:before {
                    position: absolute;
                    content: '';
                    background: url('../images/contact-footer-icon.png');
                    width: 263px;
                    height: 222px;
                    bottom: -79px;
                    left: 178px;
                }
            }

            &__title {
                font-size: 30px;
                color: $ui-09;
                text-transform: uppercase;
                width: fit-content;
                padding-bottom: 10px;
                border-bottom: 1px solid $ui-06;
                margin-bottom: 80px;
            }

            &__icons {
                position: fixed;
                bottom: 40px;
                right: 20px;
                margin-left: auto;
                margin-right: auto;
                width: fit-content;
                z-index: 999;

                a {
                    margin-right: 20px;
                    cursor: pointer;
                }
            }

            .contact__box {
                display: flex;
                flex-wrap: wrap;
                position: relative;
                z-index: 1;

                .box {
                    width: 100%;
                    line-height: 1.9;
                    margin-bottom: 30px;

                    @include breakpoint($lg) {
                        width: 33.3333%;
                    }

                    &__title {
                        font-size: 16px;
                        font-weight: bold;
                    }

                    &__text {
                        font-size: 16px;

                        a {
                            color: inherit;

                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }
        }
    }

    .menu-border {
        width: 100%;
        padding-top: 10px;

        border-bottom: 1px solid $ui-13;
        margin-bottom: 15px;
        display: none;

        @include breakpoint($lg) {
            display: block;
        }

        .nav__menu--footer {
            justify-content: center;
            width: 100%;
            margin-bottom: 0px;

            .menu__item {
                padding-bottom: 30px;

                &:hover {
                    padding-bottom: 27px;
                }
            }

        }
    }

    .created-by {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-bottom: 20px;

        a {
            width: fit-content;
            font-size: 14px;
            color: $ui-04;
            text-decoration: none;

            span {
                color: $ui-06;

            }
        }
    }

    .cookies-wrapper {
        display: none;
        position: fixed;
        z-index: 1001;
        width: 90%;
        bottom: 10px;
        left: 0;
        right: 0;
        margin: auto;

        &.active {
            display: block;
        }

        .contact-box {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            padding: 20px 30px;
            background-color: $ui-00;
            border: 1px solid $ui-01;

            @include breakpoint($lg) {
                flex-wrap: nowrap;
                align-items: center;
            }

            &__title {
                font-size: 24px;
                color: $ui-01;

                @include breakpoint($lg) {
                    flex-shrink: 0;
                    width: 190px;
                }
            }

            &__content {
                padding: 20px 0;
                font-size: 13px;
                color: $ui-03;

                @include breakpoint($lg) {
                    padding: 0 30px;
                    margin-left: 20px;
                    border-left: 1px solid $ui-01;
                }
            }

            &__close {
                padding: 15px 30px;
                color: $ui-00;
                background-color: $ui-01;
                border: none;
                cursor: pointer;

                @include breakpoint($lg) {
                    flex-shrink: 0;
                    height: 45px;
                }
            }
        }
    }

    .modal-overlay {
        display: none;
        position: fixed;
        z-index: 10000;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.5;
        box-sizing: border-box;

        &.active {
            display: block;
        }
    }

    .modal {
        display: none;
        position: fixed;
        z-index: 1000000;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 1200px;
        width: 100%;
        height: 100%;
        padding: 15px;
        box-sizing: border-box;

        &.active {
            display: flex;
            flex-direction: column;
        }

        .modal-header {
            font-size: 20px;
            color: white;
            padding: 17px 20px;
            background-color: #ed1e2b;



            .modal-close {
                padding: 0 30px;
                font-size: 26px;
                cursor: pointer;
                position: absolute;
                right: 0px;
                top: 25px;
            }
        }

        .modal-content {
            display: flex;
            flex-direction: column;
            padding: 17px 20px;
            height: auto;
            background-color: white;
            overflow-y: auto;

            .modal-response {
                display: none;
                padding: 10px 20px;
                margin-bottom: 10px;
                color: white;

                &.danger {
                    display: block;
                    background-color: #ea434e;
                }

                &.success {
                    display: block;
                    background-color: #52a554;
                }
            }

            .modal-input-group {
                margin-bottom: 20px;


                @include breakpoint($sm) {
                    flex-direction: initial;
                    display: flex;
                }

                input[type="text"] {
                    width: 100%;
                    margin-bottom: 15px;

                    @include breakpoint($sm) {
                        margin: 0 20px;
                    }

                    &:first-of-type {
                        margin-left: 0;
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }
                }
            }

            input[type="text"],
            textarea {
                padding: 6px 12px;
                font-size: 14px;
                color: #555;
                background-color: transparent;
                border: 1px solid #cfcfcf;
                box-sizing: border-box;
            }

            textarea {
                width: 100%;
                min-height: 130px;
                margin-bottom: 20px;
            }

            label {
                margin-top: 5px;
                font-size: 12px;
                color: #424242;

                a {
                    color: #ed1d2b;
                }
            }

            .text {
                margin: 20px 0;
                font-size: 12px;
                color: #424242;
            }

            button {
                height: 40px;
                line-height: 5px;
                padding: 20px;
                max-width: 300px;
                width: 100%;
                font-size: 14px;
                color: white;
                background-color: #ed1d2b;
                border: none;
                cursor: pointer;
                box-sizing: border-box;
            }
        }

        &.modal-phone {
            max-width: 400px;

            .modal-content {
                button {
                    margin: 20px 0;
                    align-self: center;
                }
            }
        }
    }
}