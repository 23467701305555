@import '../breakpoints.scss';
@import '../variables.scss';

.header {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;


    @include breakpoint($lg) {
        max-width: 1200px;
        margin: 0 auto;

    }

    &__text {
        display: none;
        font-size: 14px;
        color: $ui-03;
        margin-right: 15px;

        @include breakpoint($sm) {
            display: flex;
        }

        &:nth-of-type(3) {
            margin-right: 30px;
        }

        span {
            display: block;
            color: $ui-02;
            margin-right: 5px;
        }

        a {
            color: inherit;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &__languages {
        display: none;

        @include breakpoint($lg) {
            display: block;
        }

        .languages {
            display: flex;
            align-items: center;
            cursor: pointer;

            &--click {
                position: relative;
                z-index: 1;
                background: transparent;
                border: 0px;
                outline: none;
            }

            img:first-child {
                margin-right: 5px;
            }

            &--list {
                position: relative;
                z-index: 10000;
                flex-direction: column;
                padding-left: 8px;
                margin-top: 10px;
                opacity: 0;
                background: white;
                transition: 1s;
                visibility: hidden;

                &.show {
                    opacity: 1;
                    visibility: visible;
                }

                a {
                    padding: 5px;
                }
            }
        }
    }
}