@import '../breakpoints.scss';
@import '../variables.scss';

.content-subpage {
    &__title {
        font-size: 30px;
        color: $ui-09;
        width: fit-content;
        text-transform: uppercase;
        padding-bottom: 10px;
        border-bottom: 1px solid $ui-06;
        margin-bottom: 60px;
        line-height: 1.5;
    }

    &__text {
        font-size: 16px;
        font-weight: regular;
        color: $ui-09;
    }


}
