@import '../breakpoints.scss';
@import '../variables.scss';

.content-about {
    &__title {
        font-size: 30px;
        color: $ui-09;
        width: fit-content;
        text-transform: uppercase;
        padding-bottom: 10px;
        border-bottom: 1px solid $ui-06;
        margin-bottom: 60px;
        line-height: 1.5;
    }

    &__text {
        font-size: 16px;
        font-weight: regular;
        color: $ui-09;
    }


}

.content-about--background {
    width: 100%;
    background: $ui-08;
    padding-top: 70px;
    padding-bottom: 60px;
    margin-top: 60px;

    .content-about__box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include breakpoint($md) {
            justify-content: flex-start;
        }

        .box {
            width: 293px;
            height: 293px;

            @include breakpoint($lg) {
                border: 0.5px solid #B7B7B7 !important;
                width: 283px;
                height: 283px;
            }

            &:nth-of-type(9) {
                border-bottom: 1px solid #B7B7B7;
            }


            &--developer {
                @include breakpoint($md) {
                    width: 293px * 2 + 3px;
                }

                @include breakpoint($xl) {
                    width: 293px * 2 - 19px;
                }

                @include breakpoint($lg) {
                    border: 0px !important;
                }
            }



            &--history {
                border: 1px solid $ui-10;
                border-bottom: 0px;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                div {
                    width: 100%;
                    color: $ui-09;
                    font-size: 18px;
                    text-align: center;

                    &:nth-of-type(1) {
                        color: $ui-06;
                        font-size: 60px;
                        font-weight: bold;
                        margin-bottom: 45px;
                    }

                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }

            &--img {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    @include breakpoint($xl) {
                        width: 283px;
                        height: 283px;
                        object-fit: scale-down;
                    }
                }
            }

            &__title {
                font-size: 20px;
                color: $ui-09;
                width: fit-content;
                text-transform: uppercase;
                padding-bottom: 10px;
                border-bottom: 1px solid $ui-06;
                margin-bottom: 50px;

                @include breakpoint($md) {
                    font-size: 30px;
                }
            }

            &__text {
                font-size: 28px;
                color: $ui-09;
                width: fit-content;
                text-transform: uppercase;
                line-height: 1.5;

                @include breakpoint($md) {
                    font-size: 48px;
                }

                span {
                    width: 100%;
                    display: block;
                    font-weight: bold;
                }
            }

        }
    }
}